<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="space-100"></div>
                </div>
            </div>
            <div
                class="row"
                v-if="surveyStore.survey !== null && assessmentStore.assessment !== null"
            >
                <div class="col">
                    <rg-question-category-results
                        v-for="(questionCategory) in surveyStore.getQuestionCategoriesOrdered"
                        v-bind:key="questionCategory.ident"
                        v-bind:questionCategory="questionCategory"
                        v-bind:assessment="assessmentStore.assessment"
                        v-bind:visibility="visibility"
                    ></rg-question-category-results>
                </div>
            </div>
        </div>
        <div class="space-100"></div>
        <rg-action-block-container-results></rg-action-block-container-results>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapStores } from 'pinia'
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';
import RgQuestionCategoryResults from './rgQuestionCategoryResults.vue';
import RgActionBlockContainerResults from './rgActionBlockContainerResults.vue';

export default 
  {
    name: 'rgSurveyResults',
    data: function() {
        return {
            errorModal: {
                active: false,
                title: 'Fehler',
                content: '',
                confirmText: 'Okay',
            },
        };
    },
    methods: {
    },
    computed: {
        questionCategoriesOrdered: function() {
            return _.orderBy(_.filter(this.survey.questioncategories, function(o) { return o.active }), 'sortindex');
        },
        visibility: function() {
            return {
                isStateVisible: this.isStateVisible,
                futureStateVisible: this.futureStateVisible,
                requiredActionVisible: this.requiredActionVisible,                
            }
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    props: {
        isStateVisible: {
            type: Boolean,
            required: true,
        },
        futureStateVisible: {
            type: Boolean,
            required: true,
        },
        requiredActionVisible: {
            type: Boolean,
            required: true,
        }
    },
    components: { RgQuestionCategoryResults, RgActionBlockContainerResults },
}
</script>

<style>
</style>