<template>
    <div class="question-group-buttons-wrapper">
        <button
            class="previous-question-group-button"
            v-if="previousQuestionGroupId !== null"
            v-on:click="emitQuestionGroupSelected(previousQuestionGroupId)"
        ><md-icon class="question-group-button-icon">keyboard_arrow_up</md-icon> Vorherige Fragengruppe</button>
        <button
            class="next-question-group-button"
            v-if="nextQuestionGroupId !== null"
            v-on:click="emitQuestionGroupSelected(nextQuestionGroupId)"
        ><md-icon class="question-group-button-icon">keyboard_arrow_down</md-icon> Nächste Fragengruppe</button>
        <div class="clearfix"></div>
    </div>
</template>

<script>
export default {
    name: 'rgQuestionGroupButtonsResults',
    data: function() {
        return {};
    },
    methods: {
        emitQuestionGroupSelected: function(questionGroupId) {
            this.$emit('questionGroupSelected', questionGroupId);
        },
    },
    props: {
        previousQuestionGroupId: {
            required: false,
        },
        nextQuestionGroupId: {
            required: false,
        }
    }
}
</script>

<style scoped>
.question-group-buttons-wrapper {
    margin-left: .5em;
    padding: 1em;


    /* border-top: 2px solid var(--color-dark); */

    clear: both;
}
.question-group-buttons-wrapper button {
    padding: .75em;

    border: none;
    background: none; 

    cursor: pointer;
}
.previous-question-group-button {
    float: left;
}
.next-question-group-button {
    float: right;    
}
.question-group-button-icon {
    color: var(--color-dark) !important;
    font-size: 1.5em !important;
}

</style>