<template>
    <header class="rg-header">
        <div class="container">
            <div class="rg-header-content-wrapper">
                <div class="rg-header-content-logo-wrapper">
                    <img src="../../../assets/img/logo.png" />
                </div>
                <div class="rg-header-content-text-wrapper">
                    <h1>Ihre Reifegradauswertung</h1>
                    <div class="action-tiles-container">
                        <rg-action-tile
                            v-bind:color="'#425CA6'"
                            v-on:click="scrollTo('#overview')"
                        >
                            <h4>(1) Reife-<br>grad-<br>über-<br>sicht</h4>
                        </rg-action-tile>
                        <rg-action-tile
                            v-bind:color="'#425CA6'"
                            v-on:click="scrollTo('#top-ten-results')"
                        >
                            <h4>(2) Top 10 Handlungs-<br>bedarf</h4>
                        </rg-action-tile>
                        <rg-action-tile
                            v-bind:color="'#425CA6'"
                            v-on:click="scrollTo('#detail-results')"
                        >
                            <h4>(3) Detail-<br>ansicht</h4>
                        </rg-action-tile>
                    </div>
                    <div class="connection-line"></div> 
                    <div class="space-30"></div>
                    <rg-button styletype="color-light" v-on:click="goToAnalysis">Zurück zur Befragung</rg-button>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import RgActionTile from './rgActionTile.vue';
import RgButton from './rgButton.vue';

export default {
    name: 'rgHeader',
    data: function() {
        return {

        };
    },
    methods: {
        goToAnalysis: function() {
            this.$router.push('/analysis');
        },
        scrollTo: function(targetAnchor) {
            this.$scrollTo(targetAnchor);
        },
    },
    components: {
        RgButton,
        RgActionTile
    }
}
</script>

<style scoped>
.rg-header {
    position: relative;

    width: 100%;
    height: 40em;
}
.rg-header::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-image: url('../../../assets/img/header-results.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    -moz-filter: grayscale(1);
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}
.rg-header-content-wrapper {
    position: absolute;

    height: 100%;

    color: var(--color-light);
}
.rg-header-content-logo-wrapper {
    height: 12em;
    width: 22em;

    padding-top: 1em;

    display: flex;
    align-items: center;
}
.rg-header-content-text-wrapper {
    position: absolute;
    bottom: 0;

    z-index: 10;

    width: 100%;
    min-width: 960px;
    height: 26em;

    padding: 2em;

    text-align: center;
}
.rg-header-content-text-wrapper h1, p {
    color: var(--color-light);
}
.rg-header-content-text-wrapper::after {
    content: '';
    position: absolute;
    z-index: -10;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: var(--color-customerinterface);

    -moz-filter: opacity(0.75);
    -webkit-filter: opacity(0.75);
    filter: opacity(0.75);
}
.action-tiles-container {
    display: flex;
    justify-content: space-between;

    padding-right: 3.5em;    
    padding-left: 2em;
}
.connection-line {
    position: relative;
    margin: -4.5em 3em 0 3em;
    padding-bottom: 4.5em;
    border-top: 2px solid var(--color-light);
}

</style>