<template>
    <div class="action-block-wrapper">
        <rg-icon-tile
            v-bind:color="'#425CA6'"
            v-bind:tileColor="'#FFFFFF'"
            v-bind:iconSrc="icon"
            class="icon-tile-container"
        ></rg-icon-tile>
        <h3 class="headline">{{ headline }}</h3>
        <p>{{ text }}</p>
        <rg-button
            v-on:click="buttonTarget()"
        >{{ buttonText }}</rg-button>
    </div>
</template>

<script>
import rgButton from './rgButton.vue';
import RgIconTile from './rgIconTile.vue';
export default {
  components: { rgButton, RgIconTile },
    name: 'rgActionBlock',
    data: function() {
        return {};
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        headline: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: false,
        },
        buttonText: {
            type: String,
            required: true,
        },
        buttonTarget: {
            type: Function,
            required: true,
        }
    }
}
</script>

<style scoped>
.action-block-wrapper {
    display: inline-block;

    margin: 0 1em 0 1em;
    max-width: 12em;

    text-align: center;
    vertical-align: top;
}
.action-block-wrapper .icon-tile-container {
    display: inline-block;

    margin-left: -1.5em;
}
.action-block-wrapper .headline {
    font-size: 1.75em;
}

</style>