<template>
    <div class="question-group-table-head-index">
        <div class="question-group-table-head-index-col">0</div>
        <div class="question-group-table-head-index-col">1</div>
        <div class="question-group-table-head-index-col">2</div>
        <div class="question-group-table-head-index-col">3</div>
    </div>
</template>

<script>
export default {
    name: 'rgQuestionGroupTableHeadIndex.vue',
    data: function() {
        return {};
    },
}
</script>

<style>
.question-group-table-head-index {
    display: flex;
    flex-direction: row;

    margin-top: 1em;
    padding: .2em;

    border-top: 2px solid var(--color-dark);
}
.question-group-table-head-index .question-group-table-head-index-col {
    flex: 0 25%;    
}
</style>