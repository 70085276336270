<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <div
      v-if="!$auth.ready()"
    >
      Loading...
    </div>
    <div
      v-if="$auth.ready()"
      class="auth-wrapper"
    >
      <router-view />
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Login from './components/auth/Login.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    // Login,
  }
}
</script>

<style>
@import './assets/styles/normalize.css';
@import './assets/styles/grid.css';
@import './assets/styles/fonts.css';
@import './assets/styles/icofont.min.css';
@import './assets/styles/styles.css';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons'; /* TODO: INCLUDE ICONS INTO PROJECT? https://www.npmjs.com/package/material-icons https://developers.google.com/fonts/docs/material_icons#setup_method_2_self_hosting */

/* Define application-wide transition animations */
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
.auth-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
