<template>
    <div class="action-tile-wrapper">
        <div
            class="action-tile"
            v-bind:style="cssVars"
            v-on:click='$emit("click", $event.target.value)'
        >
            <div class="action-tile-content">
                <slot class="action-tile-title"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'rgActionTile',
    data: function() {
        return {

        };
    },
    methods: {

    },
    computed: {
        cssVars: function() {
            let cssVars = {
                '--rg-action-tile-color': this.color
            };
            if(this.tileColor) cssVars['background-color'] = this.tileColor;
            return cssVars;
        },
    },
    props: {
        color: {
            type: String,
            required: true
        },
        tileColor: {
            type: String,
            required: false,
        },
    },
}
</script>

<style scoped>
.action-tile-wrapper {
    position: relative;

    margin: 0;
    z-index: 1;
}
.action-tile {
    position: relative;
    top: 1.5em;
    left: 1.5em;

    width: 9em;
    height: 9em;
    
    padding: .75em;
    margin-bottom: 1.5em;

    background-color: var(--color-gray);
    color: var(--color-dark);

    cursor: pointer;
}
.action-tile:after {
    content: "";

    position: absolute;
    top: -1.5em;
    left: -1.5em;
    z-index: -1;

    width: 9em;
    height: 9em;

    background: var(--rg-action-tile-color);
}
.action-tile-content {
    position: relative;
    display: flex;

    height: inherit;
    width: inherit;

    margin-top: -.3em;
    margin-left: -.3em;

    justify-content: center;
    align-items: center;

    font-size: 1.3em;
    word-wrap: break-word;

}

</style>