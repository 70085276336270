<template>
    <div
        class="score-bar-wrapper"
        v-bind:style="cssVarsWrapper"
    >
        <div
            class="score-bar"
            v-bind:style="cssVars"
        ></div>
        {{ (Math.round(score * 100) / 100).toFixed(1) }}
    </div>
</template>

<script>
export default {
    name: 'rgScoreBar',
    data: function() {
        return {

        };
    },
    methods: {
    },
    computed: {
        cssVars: function() {
            let cssVars = {
                '--rg-score-bar-color': this.color,
                '--rg-score-bar-width': this.barWidth,
            };
            return cssVars;
        },
        cssVarsWrapper: function() {
            let cssVars = {
                '--rg-score-bar-max-width': this.maxWidth,
                '--rg-score-bar-max-height': this.heightInEm + 'em',
            }
            return cssVars;
        },
        barWidth: function() {
            return ((this.score / 3) * this.maxWidthInPx) + 'px';
        },
        maxWidth: function( ) {
            return this.maxWidthInEm + 'em';
        },
    },
    props: {
        color: {
            type: String,
            required: true
        },
        score: {
            required: true,
        },
        maxWidthInPx: {
            type: Number,
            required: true,
        },
        heightInEm: {
            type: Number,
            required: true,
        }
    },
}
</script>

<style scoped>
.score-bar-wrapper {
    position: relative;
    display: flex;

    padding: 0 !important;

    height: var(--rg-score-bar-max-height) !important;
    width: var(--rg-score-bar-max-width);

    align-items: center;
    justify-content: center;
}
.score-bar {
    position: relative;
    display: block;

    width: var(--rg-score-bar-width);
    height: 100%;

    margin-right: .5em;
    
    background-color: var(--rg-score-bar-color);
}
</style>