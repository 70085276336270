<template>
    <div class="admin-wrapper">
        <Navigation></Navigation>
        <Main></Main>
    </div>
</template>

<script>
// Organize imports
import Navigation from './../gui/admin/Navigation.vue';
import Main from './../gui/admin/Main.vue';

export default {
  name: 'Admin',
  data: () => {
      return {
          info: 'Info',
      };
  },
  computed: {
      _user() {
          return this.$auth.user() || {};
      },
  },
  methods: {
      logUser: function() {
          console.log(this.$auth.user());
      },
      logout: function() {
          this.$auth.logout();
          this.$router.push('/login');
      }
  },
  beforeCreate() {
      // Re-fetch and store user data from server, every time the server does a reload
      this.$auth
      .fetch()
      .then(res => {
          this.$auth.user(res.data);
      });
  },
  components: {
      Navigation,
      Main,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}
.admin-content-wrapper {
    display: flex;
    position: relative;
    height: 100%;

    background: var(--color-primary);
}
.login-wrapper {
    padding: 30px;
    margin: 3em auto 0 auto;
    max-width: 20em;
    text-align: left;
}
.login-form-element {
    display: block;
    position: relative;
    left: 0;
    right: 0;
    margin: 10px;
    padding: 10px;
}
.login-form-label {
    padding-left: 10px;
}
</style>
