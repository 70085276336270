<template>
    <div class="top-ten-results-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 center">
                    <div class="space-50" id="top-ten-results"></div>
                    <h2>(2) Top 10 Handlungsbedarf</h2>
                    <p>
                        Wo besteht der größte Handlungsbedarf hinsichtlich Digitalisierung in Ihrem Unternehmen?
                        Dies zeigen Ihnen die TOP 10 Ihrer Handlungsbedarfe, denn hier haben Sie einerseits
                        die höchste Relevanz aus Unternehmenssicht, andererseits die größte Differenz
                        zwischen SOLL- und IST-Zustand.
                    </p>
                    <p>Skala von 0-3 (0 = niedriger Handlungsbedarf, 3 = hoher Handlungsbedarf)</p>
                    <rg-top-ten-results-entry
                        v-for="(answer, index) in slicedAnswersWithScore"
                        v-bind:key="index"
                        v-bind:index="index"
                        v-bind:answer="answer"
                    ></rg-top-ten-results-entry>
                    <div class="space-50"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import rgTopTenResultsEntry from './rgTopTenResultsEntry.vue';

export default {
    name: 'rgTopTenResults',
    data: function() {
        return {};
    },
    computed: {
        slicedAnswersWithScore: function() {
            return this.assessmentStore.getAnswersWithScore.slice(0, 10);
        },
        ...mapStores(useAssessmentStore),
    },
    components: { rgTopTenResultsEntry },
}
</script>

<style scoped>

</style>