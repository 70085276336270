<template>
    <div
        class="bars-wrapper"
        v-if="surveyStore.survey !== null && assessmentStore.assessment !== null"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div
                        class="space-50"
                        id="overview"
                    ></div>
                    <h2>Reifegrad nach<br>den fünf Handlungsfeldern</h2>
                    <p>
                        Nachfolgend Sie einen Überblick über Ihren aktuellen <b>Ist-Digitalisierungsgrad, Digitalisierungsgrad in 3 Jahren</b> und den <b>Handlungsbedarf</b> je Handlungsfeld.
                    </p>
                    <!--
                    <div class="space-30"></div>
                    <div>
                        <md-radio
                            class="md-primary"
                            v-model="houseViewSelection"
                            v-bind:value="'valueToday'"
                        >Ist-Digitalisierungsgrad</md-radio>
                        <md-radio
                            class="md-primary"
                            v-model="houseViewSelection"
                            v-bind:value="'valueFuture'">
                        Soll-Digitalisierungsgrad</md-radio>
                        <md-radio
                            class="md-primary"
                            v-model="houseViewSelection"
                            v-bind:value="'necessaryAction'"
                        >Handlungsbedarf</md-radio>
                    </div>
                    -->
                    <div class="space-50"></div>
                    <div class="bars-container">
                        <div class="bars-row">
                            <div class="bars-col bar-header">Handlungsfeld</div>
                            <div class="bars-col bar-header">Ist-Digitalisierungsgrad</div>
                            <div class="bars-col bar-header">Digitalisierungsgrad in 3 Jahren</div>
                            <div class="bars-col bar-header">Handlungsbedarf</div>
                        </div>
                        <div class="bars-row" v-for="category, i in displayCategories" v-bind:key="i">
                            <div class="bars-col score-bar-label">{{ category.name }}</div>
                            <div class="bars-col score-bar-wrapper">
                                <rg-score-bar
                                    class="score-bar"
                                    v-bind:color="'gray'"
                                    v-bind:score="(Math.round(assessmentStore.getScoreByQuestionCategory('valueToday', category.ident) * 100)*3)/100"
                                    v-bind:maxWidthInPx="200"
                                    v-bind:heightInEm="2"
                                ></rg-score-bar>
                            </div>
                            <div class="bars-col score-bar-wrapper">
                                <rg-score-bar
                                    class="score-bar"
                                    v-bind:color="'#425CA6'"
                                    v-bind:score="(Math.round(assessmentStore.getScoreByQuestionCategory('valueFuture', category.ident) * 100)*3)/100"
                                    v-bind:maxWidthInPx="200"
                                    v-bind:heightInEm="2"
                                ></rg-score-bar>
                            </div>
                            <div class="bars-col score-bar-wrapper">
                                <rg-score-bar
                                    class="score-bar"
                                    v-bind:color="'#FF5252'"
                                    v-bind:score="(Math.round(assessmentStore.getScoreByQuestionCategory('valueFuture', category.ident) * 100)*3)/100 - (Math.round(assessmentStore.getScoreByQuestionCategory('valueToday', category.ident) * 100)*3)/100"
                                    v-bind:maxWidthInPx="200"
                                    v-bind:heightInEm="2"
                                ></rg-score-bar>
                            </div>
                        </div>
                    </div>
                    <!--
                    <p v-if="houseViewSelection === 'valueToday'">
                        Der IST-Digitalisierungsgrad beschreibt Ihre aktuelle Sicht auf Ihr Unternehmen.
                        Es ist der bis heute beschrittene Weg in der Digitalisierung, den Sie Ihrer Einschätzung nach 
                        bereits gegangen sind.
                    </p>
                    <p v-if="houseViewSelection === 'valueFuture'">
                        Der SOLL-Digitalisierungsgrad zeigt Ihnen die zukünftig gewünschte Sicht  auf Ihr Unternehmen
                        in der Digitalisierung. Es ist ein Ziel, welches Sie in den kommenden Jahren erreichen möchten.
                    </p>
                    <p v-if="houseViewSelection === 'necessaryAction'">
                        Der Handlungsbedarf zeigt Ihnen auf, welche Wege Sie noch zu gehen haben, um an ihr Soll-Ziel zu
                        gelangen. Er errechnet sich aus dem IST-Digitalisierungsgrad, dem SOLL-Digitalisierungsgrad und
                        der angegebenen Relevanz für Ihr Unternehmen.
                    </p>
                    -->
                    <div class="space-100"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';
import RgScoreBar from './rgScoreBar.vue';

export default {
    name: 'rgBarsOverallResults',
    data: function() {
        return {
            displayCategories: [
                {
                    ident: 'category-1',
                    name: 'A: Digitalisierungsstrategie & Geschäftsmodell',
                },
                {
                    ident: 'category-2',
                    name: 'B: Partnerschnittstelle',
                },
                {
                    ident: 'category-3',
                    name: 'C: Unternehmen',
                },
                {
                    ident: 'category-4',
                    name: 'D: Produkte & Services',
                },
                {
                    ident: 'category-5',
                    name: 'E: Kundenschnittstelle',
                },
            ],
        };
    },
    methods :{
    },
    computed: {
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    components: { RgScoreBar }
}
</script>

<style scoped>

.bars-wrapper {
    display: flex;

    text-align: center;
    justify-content: center;

    background-color: var(--color-gray);
}
.bars-container {

    justify-content: center;

    /*
    display: flex;

    /*
    position: relative;

    */
}
.bars-row {
    display: flex;
    justify-content: left;

    padding-top: .5em;
    padding-bottom: .5em;
}
.bars-col {
    display: flex;
    justify-content: left;
    align-items: center;

    flex: 1 0 0;
} 
.score-bar-label {
    padding-right: 3em;

    justify-content: right;
    text-align: right;
}
.bar-header:first-child {
    padding-right: 3em;

    justify-content: right;
}
.bar-header {
    font-weight: bold;

    justify-content: left;

    flex: 1 0 0;
}
.score-bar-wrapper {
    display: flex;
    justify-content: left;
}

</style>