<template>
    <div
        v-bind:style="cssVarsCategoryBodyBorder"
        class="question-category-body-wrapper"
    >
        <rg-question-group-results
            class="rg-question-group"
            v-for="(questionGroup, index) in questionGroupsOrdered"
            v-bind:key="questionGroup.ident"
            v-bind:questionGroup="questionGroup"
            v-bind:displaycolor="displaycolor"
            v-bind:selectedGroup="selectedGroup"
            v-bind:assessment="assessment"
            v-bind:previousQuestionGroupId="(index > 0) ? (questionGroupsOrdered[index-1]['id']) : (null)"
            v-bind:nextQuestionGroupId="(index < questionGroupsOrdered.length-1) ? (questionGroupsOrdered[index+1]['id']) : (null)"
            v-on:groupSelected="groupSelected"
            v-bind:visibility="visibility"
        ></rg-question-group-results>
    </div>
</template>

<script>
import _ from 'lodash';
import RgQuestionGroupResults from './rgQuestionGroupResults.vue';

export default {
    name: 'rgQuestionCategoryBodyResults',
    data: function() {
        return {
            selectedGroup: '',
        };
    },
    computed: {
        questionGroupsOrdered: function() {
            return _.orderBy(_.filter(this.questionGroups, function(o) { return o.active }), 'sortindex');
        },
        cssVarsCategoryBodyBorder: function() {
            return {
                '--rg-question-category-body-border': this.displaycolor,
            };
        },
    },
    methods: {
        groupSelected: function(id) {
            this.selectedGroup = id;
        },
    },
    props: {
        questionGroups: {
            type: Array,
        },
        displaycolor: {
            required: true,
            type: String,
        },
        assessment: {
            required: true,
            type: Object,
        },
        visibility: {
            type: Object,
            required: true,
        },
    },
    components: { RgQuestionGroupResults },
}
</script>

<style>
.question-category-body-wrapper {
    position: relative;

    margin-left: .625em;

    border-left-color: var(--rg-question-category-body-border);
    border-left-width: 2px;
    border-left-style: solid;
}
/*
.rg-question-group:first-child > .question-group-container > .question-group-headline {
    margin-top: -0.25em;
}
.rg-question-group:last-child > .question-group-container > .question-group-headline {
    margin-bottom: -0.25em;
}
.rg-question-group:last-child > .question-group-container {
    border-bottom: none;
}
*/
</style>