<template>
    <div
        class="question-group-milestone"
        v-bind:class="milestoneStatus"
        v-bind:style="cssVarsDisplaycolor"
    >
        <span class="material-icons milestone-icon-dark" v-if="milestoneStatus === 'postponed'">close</span>
        <span class="material-icons milestone-icon-light" v-else-if="milestoneStatus === 'finished'">done</span>
        <span class="material-icons milestone-icon" v-else></span>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
export default {
    name: 'rgQuestionGroupMilestone',
    data: function() {
        return {};
    },
    computed: {
        cssVarsDisplaycolor: function() {
            return {
                '--rg-question-group-milestone-displaycolor': this.displaycolor,
            };            
        },
        milestoneStatus: function() {
            if(this.assessmentStore.isQuestionGroupPostponed(this.questionGroup.id)) return "postponed";
            if(this.assessmentStore.isQuestionGroupFinished(this.questionGroup.id)) return "finished";
            return "unfinished";
        },
        ...mapStores(useAssessmentStore),
    },
    props: {
        displaycolor: {
            type: String,
            required: true,
        },
        questionGroup: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped>
.question-group-milestone {
    display: inline;
    position: absolute;

    margin-top: -1px;
    left: -0.7em;

    width: 1.25em;
    height: 1.25em;

    -moz-border-radius: .625em; 
    -webkit-border-radius: .625em; 
    border-radius: .625em;

    border: 1px solid var(--rg-question-group-milestone-displaycolor);
}
.postponed {
    background-color: var(--color-error-shade);
    color: var(--color-dark);
}
.finished {
    background-color: var(--rg-question-group-milestone-displaycolor);
}
.unfinished {
    background-color: var(--color-gray);
}
.milestone-icon-light {
    color: var(--color-light);
    font-size: 1.1em;
}
.milestone-icon-dark {
    color: var(--color-dark);
    font-size: 1.15em;
}

</style>