<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="space-100"></div>
                </div>
            </div>
            <div
                class="row"
                v-if="surveyStore.survey !== null && assessmentStore.assessment !== null"
            >
                <div class="col">
                    <rg-question-category
                        v-for="(questionCategory) in surveyStore.getQuestionCategoriesOrdered"
                        v-bind:key="questionCategory.ident"
                        v-bind:questionCategory="questionCategory"
                        v-bind:assessment="assessmentStore.assessment"
                    ></rg-question-category>
                </div>
            </div>
        </div>
        <div class="space-100"></div>
        <rg-action-block-container></rg-action-block-container>
    </div>
</template>

<script>
import rgQuestionCategory from "./rgQuestionCategory.vue";
import rgActionBlockContainer from "./rgActionBlockContainer.vue";
import _ from 'lodash';
import { mapStores } from 'pinia'
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';

export default 
  {
    name: 'rgSurvey',
    data: function() {
        return {
            errorModal: {
                active: false,
                title: 'Fehler',
                content: '',
                confirmText: 'Okay',
            },
        };
    },
    methods: {
    },
    computed: {
        questionCategoriesOrdered: function() {
            return _.orderBy(_.filter(this.survey.questioncategories, function(o) { return o.active }), 'sortindex');
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    components: { rgQuestionCategory, rgActionBlockContainer },
}
</script>

<style>
</style>