<template>
    <div class="question-wrapper">
        <div
            class="parent-question-wrapper"
        >
            <div class="question-name question-spacer">
                <div
                    v-if="!isSubquestion"
                    class="question-label"
                >
                    Frage {{ surveyStore.getQuestionIndexById(question.id) + 1 }}
                </div>
                <p class="question-text">
                    {{ question.name }}
                </p>
            </div>
            <div class="question-cols rating-header">
                <rg-answer-radio
                    class="gray-shade"
                    v-if="!subquestionsOrdered.length > 0"
                    v-bind:displaycolor="displaycolor"
                    v-model="pertinance"
                    v-on:input="valueChanged"
                ></rg-answer-radio>
            </div>
            <div class="question-cols rating-header">
                <rg-answer-radio
                    class="gray"
                    v-if="!subquestionsOrdered.length > 0 && pertinance !== 0"
                    v-bind:displaycolor="displaycolor"
                    v-model="valueToday"
                    v-on:input="valueChanged"
                ></rg-answer-radio>
            </div>
            <div class="question-cols rating-header">
                <rg-answer-radio
                    class="gray"
                    v-if="!subquestionsOrdered.length > 0 && pertinance !== 0"                    
                    v-bind:displaycolor="displaycolor"
                    v-model="valueFuture"
                    v-on:input="valueChanged"
                ></rg-answer-radio>
            </div>
            <div class="question-cols postpone">
                <md-switch
                    class="question-postpone-switch"
                    v-if="!subquestionsOrdered.length > 0"
                    v-model="postponed"
                    v-bind:style="cssVarsToggle"
                    v-on:change="valueChanged"
                ></md-switch>
            </div>
        </div>
        <div
            class="child-questions-wrapper"
            v-if="subquestionsOrdered.length > 0"
        >
            <rg-question
                v-for="subquestion in subquestionsOrdered"
                v-bind:key="subquestion.ident"
                v-bind:question="subquestion"
                v-bind:displaycolor="displaycolor"
                v-bind:isSubquestion="true"
                v-on:answerStatusChanged="emitAnswerStatus"
            ></rg-question>
        </div>
        <div class="error-modal-wrapper">
            <md-dialog-alert
                v-bind:md-active.sync="errorModal.active"
                v-bind:md-title="errorModal.title"
                v-bind:md-content="errorModal.content"
                v-bind:md-confirm-text="errorModal.confirmText"
            ></md-dialog-alert>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import rgAnswerRadio from './rgAnswerRadio.vue';
import { mapActions, mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';

export default {
    name: 'rgQuestion',
    data: function() {
        return {
            answerId: null,
            pertinance: null,
            valueToday: null,
            valueFuture: null,
            postponed: false,
            errorModal: {
                active: false,
                title: 'Fehler',
                content: '',
                confirmText: 'Okay',
            }
        };
    },
    computed: {
        subquestionsOrdered: function() {
            if(this.hasSubquestions) {
                return _.orderBy(_.filter(this.question.subquestions, function(o) { return o.active }), 'sortindex');
            } else {
                return [];
            }
        },
        cssVarsToggle: function() {
            return {
                '--md-theme-default-accent': this.displaycolor,
                '--md-theme-default-accent-on-': 'blue !important',
            };
        },
        hasSubquestions: function() {
            if(_.has(this.question, 'subquestions')) {
                if(this.question.subquestions.length > 0) {
                    return true;
                }
            }
            return false;
        },
        isAnswered: function() {
            return this.assessmentStore.isQuestionAnswered(this.answerId);
        },
        isPostponed: function() {
            return this.isPostponed;
        },
        computedPostponed: function() {
            return this.assessmentStore.assessment.answers[0].postponed;
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    methods: {
        valueChanged: function() {
            this.createOrUpdateAnswer();
        },
        createOrUpdateAnswer: async function() {
            const _this = this;
            let body = {
                assessment: this.assessmentStore.assessment.id,
                postponed: this.postponed,
            };

            // If pertinance is 0, set valueToday and valueFuture back to null
            if(this.pertinance == 0) {
                this.valueToday = null;
                this.valueFuture = null;
            }

            body['pertinance'] = this.pertinance;
            body['valueToday'] = this.valueToday;
            body['valueFuture'] = this.valueFuture;

            // Either add the question ID to question or subquestion
            body[(this.isSubquestion) ? ('subquestion') : ('question')] = this.question.id;

            // Define if answer needs to be created or updated and fire request
            if(this.answerId === null) { 
                this.$http
                    .post(('/answers'), body)
                    .then(function(response) {
                        _this.assessmentStore.createAnswer(response.data);
                        if(_this.answerId === null) _this.answerId = response.data.id;
                    })
                    .catch(function(e) {
                        console.log(e);
                        _this.openErrorModal("Ihre Antwort konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Internetverbindung oder kontaktieren Sie unseren Support, falls das Problem weiterhin besteht.");
                    });
            } else {
                this.$http
                    .patch(('/answers/' + this.answerId), body)
                    .then(function() {
                        body['id'] = _this.answerId;
                        _this.assessmentStore.updateAnswer(body);
                    })
                    .catch(function(e) {
                        console.log(e);
                        _this.openErrorModal("Ihre Antwort konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Internetverbindung oder kontaktieren Sie unseren Support, falls das Problem weiterhin besteht.");
                    });
            }
        },
        setAnswer: function() {
            const _this = this;

            // Select answer for the given question from assessment - if present
            const answer = _.find(this.assessmentStore.assessment.answers, (o) => {
                if(_this.isSubquestion && !_.isNull(o.subquestion)) {
                    return o.subquestion.id === _this.question.id;
                } else if (!_this.isSubquestion && !_.isNull(o.question)) {
                    return o.question.id === _this.question.id;
                } else {
                    return false;
                }
            });

            if(answer) {
                this.answerId = answer.id;
                this.pertinance = answer.pertinance;
                this.valueToday = answer.valueToday;
                this.valueFuture = answer.valueFuture;
                this.postponed = answer.postponed;
            }
        },
        emitAnswerStatus: function(status) {
            this.$emit('answerStatusChanged', {
                questionId: status.questionId,
                answerStatus: status.answerStatus,
            });
        },
        emitPostponementStatus: function() {
            this.$emit('postponementStatusChanged', {
                questionId: this.question.id,
                postponementStatus: this.isPostponed,
            });
        },
        openErrorModal: function(msg) {
            this.errorModal.content = msg;
            this.errorModal.active = true;
        },
        ...mapActions(useAssessmentStore, ['isQuestionAnswered']),
    },
    created: function() {
        this.setAnswer();
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        displaycolor: {
            type: String,
            required: true,
        },
        isSubquestion: {
            type: Boolean,
            required: true,
        },
    },
    components: { rgAnswerRadio },
}
</script>

<style scoped>
.parent-question-wrapper {
    display: flex;
    flex-direction: row;

}
.parent-question-wrapper .question-cols {
    flex: 1 1 1;

    vertical-align: top;
    text-align: center;

}
.question-spacer {
    width: 36%;

    border-bottom: none !important;
}
.question-label {
    position: absolute;
    margin-left: -4.25em;
    margin-top: 1.1em;

    background-color: var(--color-light);
    padding: .25em;

    font-size: .9em;
    font-weight: bold;
    color: var(--color-dark);
}
.question-text {
    max-width: 14em;

    font-size: .95em;  
    
    padding-left: .5em;
    padding-right: .5em;

    margin-bottom: .75em;

    word-break: normal;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
.child-questions-wrapper .question-text {
    font-size: .95em;  
    
    padding-left: 1.5em;
    padding-right: .5em;
}
.rating-header {
    width: 18%;

    border-left: 2px solid var(--color-dark);
}
.postpone {
    width: 12%;
    
    font-size: .7em;
    line-height: 1.4em;

    border-left: 2px solid var(--color-dark);
    border-right: 2px solid var(--color-dark);
}
.gray {
    background: var(--color-gray);
}
.gray-shade {
    background: var(--color-gray-shade);
}
.question-postpone-switch {
    margin-top: 1.5em;
}
.md-switch.md-theme-default.md-checked .md-switch-container {
    background-color: none !important;
}

</style>