<template>
    <div class="answer-radio-wrapper">
        <div class="answer-radio-col">
            <rg-answer-radio-button
                v-on:buttonClicked="buttonClicked"
                v-bind:buttonValue="0"
                v-bind:displaycolor="displaycolor"
                v-bind:currentRadioValue="value"
            ></rg-answer-radio-button>
        </div>
        <div class="answer-radio-col">
            <rg-answer-radio-button
                v-on:buttonClicked="buttonClicked"
                v-bind:buttonValue="1"
                v-bind:displaycolor="displaycolor"
                v-bind:currentRadioValue="value"
            ></rg-answer-radio-button>
        </div>
        <div class="answer-radio-col">
            <rg-answer-radio-button
                v-on:buttonClicked="buttonClicked"
                v-bind:buttonValue="2"
                v-bind:displaycolor="displaycolor"
                v-bind:currentRadioValue="value"
            ></rg-answer-radio-button>
        </div>
        <div class="answer-radio-col">
            <rg-answer-radio-button
                v-on:buttonClicked="buttonClicked"
                v-bind:buttonValue="3"
                v-bind:displaycolor="displaycolor"
                v-bind:currentRadioValue="value"
            ></rg-answer-radio-button>
        </div>
    </div>
</template>

<script>
import rgAnswerRadioButton from './rgAnswerRadioButton.vue';
export default {
    name: 'rgAnswerRadio.vue',
    data: function() {
        return {};
    },
    methods: {
        buttonClicked: function(value) {
            // this.value = value;

            this.$emit('input', value);
        },
    },
    props: {
        displaycolor: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: false,
        }
    },
    components: { rgAnswerRadioButton },
}
</script>

<style scoped>
.answer-radio-wrapper {
    display: flex;
    flex-direction: row;

    height: 100%;

    padding: .2em;
    padding-top: 1em;
}
.answer-radio-wrapper .answer-radio-col {
    display: flex;
    flex: 0 25%;    

    justify-content: center;
}
</style>