<template>
    <div
        class="question-category-wrapper"
    >
        <a v-bind:id="questionCategory.ident"></a>
        <rg-question-category-header-results
            v-bind:displaycolor="questionCategory.displaycolor"
            v-bind:icon="icon"
            v-bind:name="questionCategory.name"
            v-bind:questionCategory="questionCategory"
        ></rg-question-category-header-results>
        <div class="space-70"></div>
        <div v-if="!questionCategory.questiongroups.length > 0">Diese Fragenkategorie enthält derzeit keine Fragengruppen.</div>
        <rg-question-category-body-results
            v-bind:displaycolor="questionCategory.displaycolor"
            v-bind:questionGroups="questionCategory.questiongroups"
            v-bind:assessment="assessment"
            v-bind:visibility="visibility"
        ></rg-question-category-body-results>
        <div class="space-100"></div>
    </div>
</template>

<script>
import RgQuestionCategoryBodyResults from './rgQuestionCategoryBodyResults.vue';
import RgQuestionCategoryHeaderResults from './rgQuestionCategoryHeaderResults.vue';

export default {
    name: 'rgQuestionCategoryResults',
    data: function() {
        return {};
    },
    methods: {},
    props: {
        'questionCategory': {
            required: true,
            type: Object,
        },
        assessment: {
            required: true,
            type: Object,
        },
        visibility: {
            type: Object,
            required: true,
        }
    },
    computed: {
        icon: function() {
            return (process.env.VUE_APP_API_URL) ? (process.env.VUE_APP_API_URL) : ('https://reifegradanalyse.hnu.de/api') + this.questionCategory.icon;
        },
    },
    components: { RgQuestionCategoryHeaderResults, RgQuestionCategoryBodyResults },
}
</script>

<style scoped>

</style>