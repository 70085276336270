<template>
    <div class="references-wrapper">
        <div class="space-50"></div>
        <rg-text-block>
            <template v-slot:headline>
                Referenzen
            </template>
            <template v-slot:content>
                Diese Unternehmen hatten bereits erste Erfolge mit unserem Reifegrad-Onlinetool und Ihrem Weg in Richtung digitaler Transformation des Unternehmens.
                Vielen herzlichen Dank für die Unterstützung und Teilnahme bei unserem InnoSüd Projekt
                <a href="https://innosued.de/transformationsmanagement/digitaler-reifegrad-mittelstand/" target="_blank">„Digitaler Reifegrad@Mittelstand“</a> des Programms Innovative Hochschule.
            </template>
        </rg-text-block>
        <div class="container">
            <div class="row references-container">
                <div
                    class="col-4 logo-wrapper"
                    v-for="(reference, index) in references"
                    v-bind:key="index"
                    v-on:click="openWebsite(reference.link)"
                >
                    <img v-bind:src="reference.img">
                </div>
            </div>
        </div>
        <div class="space-100"></div>
    </div>
</template>

<script>
import rgTextBlock from './rgTextBlock.vue';
export default {
  components: { rgTextBlock },
    data: function() {
        return {
            references: [
                {
                    img: require('../../../assets/img/references/stiefel.png'),
                    link: 'https://stiefel.de/Pneumatik',
                },
                {
                    img: require('../../../assets/img/references/conplaning.png'),
                    link: 'https://conplaning.de/',
                },
                {
                    img: require('../../../assets/img/references/pfersich.png'),
                    link: 'https://www.pfersich.de/startseite',
                },
                {
                    img: require('../../../assets/img/references/fischer.png'),
                    link: 'https://www.fischer-fahrzeuge.de/de/',
                },
                {
                    img: require('../../../assets/img/references/meba.png'),
                    link: 'https://www.meba-saw.com/',
                },
            ],
        };
    },
    methods: {
        openWebsite: function(url) {
            window.open(url, '_blank').focus();            
        },
    }
}
</script>

<style scoped>
.references-wrapper {
    background-color: var(--color-gray);
}
.logo-wrapper {
    display: flex;
    height: 12em;

    padding: 1em;

    align-items: center;
    justify-content: center;

    cursor: pointer;
}

</style>