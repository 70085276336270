<template>
    <div
        class="house-wrapper"
        v-if="surveyStore.survey !== null && assessmentStore.assessment !== null"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div
                        class="space-50"
                        id="overview"
                    ></div>
                    <h2>Reifegrad hinsichtlich der Handlungsfelder der digitalen Transformation</h2>
                    <p>
                        Die Ergebnisse im Digitalisierungshaus zeigen einen Überblick über den
                        Digitalisierungsgrad Ihres Unternehmens in den einzelnen Handlungsfeldern
                        aus der aktuellen Sicht, der gewünschten zukünftigen Sicht und der Sicht
                        auf die bestehende Handlungsnotwendigkeit. Je nach Wahl des Buttons, erhalten
                        Sie Einblick in die einzelnen Handlungsfelder Ihres Unternehmens.
                        <br><br>
                        Skala von 0-3 (0 = niedrig, 3 = hoch)
                    </p>
                    <div class="space-30"></div>
                    <div>
                        <md-radio
                            class="md-primary"
                            v-model="houseViewSelection"
                            v-bind:value="'valueToday'"
                        >Ist-Digitalisierungsgrad</md-radio>
                        <md-radio
                            class="md-primary"
                            v-model="houseViewSelection"
                            v-bind:value="'valueFuture'">
                        Digitalisierungsgrad in 3 Jahren</md-radio>
                        <md-radio
                            class="md-primary"
                            v-model="houseViewSelection"
                            v-bind:value="'necessaryAction'"
                        >Handlungsbedarf</md-radio>
                    </div>
                    <div class="space-50"></div>
                    <div class="house-container">
                        <img class="house-image" src="../../../assets/img/house-results.svg" />
                        <div class="roof-overlay" id="a" v-bind:style="{ 'border-bottom-width': a * 85 + 'px' }">
                        </div>
                        <div class="tile-content-roof">{{ (Math.round(a * 100)*3)/100 }}</div>
                        <div class="tile-overlay" id="b" v-bind:style="{ 'height': b * 100 + 'px' }">
                            <div class="tile-content">{{ (Math.round(b * 100)*3)/100 }}</div>
                        </div>
                        <div class="tile-overlay" id="c" v-bind:style="{ 'height': c * 100 + 'px' }">
                            <div class="tile-content">{{ (Math.round(c * 100)*3)/100 }}</div>
                        </div>
                        <div class="tile-overlay" id="d" v-bind:style="{ 'height': d * 100 + 'px' }">
                            <div class="tile-content">{{ (Math.round(d * 100)*3)/100 }}</div>
                        </div>
                        <div class="tile-overlay" id="e" v-bind:style="{ 'height': e * 100 + 'px' }">
                            <div class="tile-content">{{ (Math.round(e * 100)*3)/100 }}</div>
                        </div>
                    </div>
                    <p v-if="houseViewSelection === 'valueToday'">
                        Der IST-Digitalisierungsgrad beschreibt Ihre aktuelle Sicht auf Ihr Unternehmen.
                        Es ist der bis heute beschrittene Weg in der Digitalisierung, den Sie Ihrer Einschätzung nach 
                        bereits gegangen sind.
                    </p>
                    <p v-if="houseViewSelection === 'valueFuture'">
                        Der SOLL-Digitalisierungsgrad zeigt Ihnen die zukünftig gewünschte Sicht  auf Ihr Unternehmen
                        in der Digitalisierung. Es ist ein Ziel, welches Sie in den kommenden Jahren erreichen möchten.
                    </p>
                    <p v-if="houseViewSelection === 'necessaryAction'">
                        Der Handlungsbedarf zeigt Ihnen auf, welche Wege Sie noch zu gehen haben, um an ihr Soll-Ziel zu
                        gelangen. Er errechnet sich aus dem IST-Digitalisierungsgrad, dem SOLL-Digitalisierungsgrad und
                        der angegebenen Relevanz für Ihr Unternehmen.
                    </p>
                    <div class="space-40"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';

export default {
    name: 'rgHouse',
    data: function() {
        return {
            houseViewSelection: 'valueToday',
        };
    },
    computed: {
        a: function() {
            return this.assessmentStore.getScoreByQuestionCategory(this.houseViewSelection, 'category-1');
        },
        b: function() {
            return this.assessmentStore.getScoreByQuestionCategory(this.houseViewSelection, 'category-2');
        },
        c: function() {
            return this.assessmentStore.getScoreByQuestionCategory(this.houseViewSelection, 'category-3');
        },
        d: function() {
            return this.assessmentStore.getScoreByQuestionCategory(this.houseViewSelection, 'category-4');
        },
        e: function() {
            return this.assessmentStore.getScoreByQuestionCategory(this.houseViewSelection, 'category-5');
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
}
</script>

<style scoped>

.house-wrapper {
    display: flex;

    text-align: center;
    justify-content: center;

    background-color: var(--color-gray);
}
.house-container {
    position: relative;
    display: flex;

    justify-content: center;
}
.house-image {
    max-width: 750px;
    width: 750px;
}
.roof-overlay {
    position: absolute;

    bottom: 301px;

    margin-left: -7px;

    width: 0;
    height: 0;
    border-style: solid;
    border-top-width: 0;
    border-right-width: 195px;
    /* border-bottom-width: 85px; */
    border-left-width: 195px;
    border-color: transparent transparent var(--color-digitalstrategy) transparent;

    border-style: inset;
    -webkit-transform:rotate(360deg);
}
.tile-overlay {
    position: absolute;

    bottom: 174px;

    max-height: 200px;
    width: 85px;

    color: var(--color-light);
}
.tile-content {
    position: absolute;

    width: 100%;

    bottom: 0;
    padding-bottom: .5em;

    font-weight: bold;

    color: var(--color-light);
}
.tile-content-roof {
    position: absolute;

    width: 100%;

    bottom: 300px;
    padding-bottom: .5em;

    font-weight: bold;

    color: var(--color-light);
}
#a {
    /*
    height: 2em;
    width: 385px;
    */

    /* clip-path: polygon(42% 69%, 60% 69%, 100% 100%, 0% 100%); */
}
#b {
    margin-left: -311px;
    background-color: var(--color-partnerinterface);
}
#c {
    margin-left: -109px;
    background-color: var(--color-company);
}
#d {
    margin-right: -92px;
    background-color: var(--color-productsandservices);
}
#e {
    margin-right: -295px;
    background-color: var(--color-customerinterface);
}

</style>