<template>
    <div class="question-category-progress-text-wrapper">
        <div class="question-category-progress-bar-label">Fortschritt:&nbsp;<b>{{ assessmentStore.getAnswersCountByQuestioncategory(questionCategory.id) }}</b>&nbsp;von&nbsp;<b>{{ surveyStore.getQuestionsCountByQuestioncategory(questionCategory.id) }}</b>&nbsp;Antwortmöglichkeiten</div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';
export default {
    name: 'rgQuestionCategoryProgressText',
    data: function() {
        return {};
    },
    computed: {
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    props: {
        color: {
            required: true,
            type: String,
        },
        questionCategory: {
            required: true,
            type: Object,
        }
    }

}
</script>

<style scoped>
.question-category-progress-text-wrapper {
    position: relative;
    bottom: 0;
    
    min-width: 60%;
    height: 1.5em;

    float: left;
}
.question-category-progress-bar-label {
    position: absolute;    
    height: 100%;

    margin-top: 1em;

    /* color: var(--color-light); */
}

</style>