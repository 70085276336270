<template>
    <div class="question-group-table-head-wrapper">
        <div class="question-group-table-head-cols question-spacer">
            <div>&nbsp;</div>
            <div class="question-spacer-divider">&nbsp;</div>
        </div>
        <div class="question-group-table-head-cols rating-header">
            <div>
                Relevanz
                <md-icon class="information-icon">
                    info_outline
                    <md-tooltip md-direction="top" class="table-head-tooltip">
                        Relevanz:
                        Wie wichtig ist die Frage in Ihrem Unternehmen?
                        0 = nicht relevant/kann nicht beurteilt werden … 3 = sehr relevant
                    </md-tooltip>
                </md-icon>
            </div>
            <rg-question-group-table-head-index class="gray-shade"></rg-question-group-table-head-index>
        </div>
        <div class="question-group-table-head-cols rating-header">
            <div>
                Ist-Zustand
                <md-icon class="information-icon">
                    info_outline
                    <md-tooltip md-direction="top" class="table-head-tooltip">
                        IST-Stand:
                        Wie sieht der aktuelle Status quo der Frage in Ihrem Unternehmen aus?
                        0 = nicht existent … 3 = existiert vollumfassend auf eine hohen Niveau
                    </md-tooltip>
                </md-icon>
            </div>
            <rg-question-group-table-head-index class="gray"></rg-question-group-table-head-index>
        </div>
        <div class="question-group-table-head-cols rating-header">
            <div>
                Soll-Zustand
                <md-icon class="information-icon">
                    info_outline
                    <md-tooltip md-direction="top" class="table-head-tooltip">
                        SOLL-Stand:
                        Wo möchte Ihr Unternehmen in der Frage in 3 Jahren stehen?
                        0 = wird nicht forciert … 3 = auf einem vollumfassenden und hohen Niveau
                    </md-tooltip>
                </md-icon>
            </div>
            <rg-question-group-table-head-index class="gray"></rg-question-group-table-head-index>
        </div>
        <div class="question-group-table-head-cols postpone">Fragen<br>später<br>beantworten</div>
    </div>
</template>

<script>
import RgQuestionGroupTableHeadIndex from './rgQuestionGroupTableHeadIndex.vue';
export default {
    name: 'rgQuestionGroupTableHead',
    data: function() {
        return {};
    },
    components: {
        RgQuestionGroupTableHeadIndex
    }
}
</script>

<style scoped>
.question-group-table-head-wrapper {
    display: flex;
    flex-direction: row;
}
.question-group-table-head-wrapper .question-group-table-head-cols {
    flex: 1 1 1;

    vertical-align: top;
    text-align: center;

    border-bottom: 2px solid var(--color-dark);
}
.question-spacer {
    width: 36%;

    border-bottom: none !important;
}
.question-spacer-divider {
    margin-top: 1em;
    padding: .2em;

    border-top: 2px solid var(--color-dark);
}
.rating-header {
    width: 18%;

    border-left: 2px solid var(--color-dark);
}
.information-icon {
    display: inline-block;
    width: 10px;
    height: 10px;

    margin-top: -.5em;

    font-size: 1em !important;    
    line-height: inherit;
}
.table-head-tooltip {
    font-size: var(--font-size-default);
}
.postpone {
    width: 12%;
    
    font-size: .8em;
    line-height: 1.4em;

    border-left: 2px solid var(--color-dark);
    border-right: 2px solid var(--color-dark);
}
.gray {
    background: var(--color-gray);
}
.gray-shade {
    background: var(--color-gray-shade);
}

</style>