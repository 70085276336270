<template>
    <div class="number-tile-wrapper">
        <div
            class="number-tile"
            v-bind:style="cssVars"
        >
            <div class="number-container">{{ number }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'rgNumberTile',
    data: function() {
        return {

        };
    },
    methods: {

    },
    computed: {
        cssVars: function() {
            let cssVars = {
                '--rg-number-tile-color': this.color
            };
            if(this.tileColor) cssVars['background-color'] = this.tileColor;
            return cssVars;
        },
    },
    props: {
        color: {
            type: String,
            required: true
        },
        number: {
            type: Number,
            required: true
        },
        tileColor: {
            type: String,
            required: false,
        },
    },
}
</script>

<style scoped>
.number-tile-wrapper {
    position: relative;

    margin: 0;
    z-index: 1;
}
.number-tile {
    position: relative;
    top: .5em;
    left: .5em;

    width: 2.25em;
    height: 2.25em;
    
    padding: 0;

    background-color: var(--color-gray);

    line-height: 0;
}
.number-tile:after {
    content: "";

    position: absolute;
    top: -.5em;
    left: -.5em;
    z-index: -1;

    width: 2.25em;
    height: 2.25em;

    background: var(--rg-number-tile-color);
}
.number-container {
    position: relative;

    font-size: 2.05em;
    color: var(--color-dark);
    text-align: center;
}

</style>