<template>
    <section class="pdf-item">
        <div class="pdf-wrapper">
            <h2 class="pdf-headline">(3) Detailansicht</h2>
            <p>
                In der Detailansicht sehen Sie die Ergebnisse zu all Ihren beantworteten Fragen. Es wird Ihnen die Relevanz,
                der IST-Digitalisierungsgrad, der SOLL-Digitalisierungsgrad und der Handlungsbedarf zu jeder Fragen in den
                unterteilten Fragengruppen angezeigt.
                <br>
                <br>
                Skala von 0-3 (0 = niedrig, 3 = hoch)
            </p>
            <div class="parent-question-wrapper">
                <div class="question-name question-spacer">
                    <p class="question-text">
                        <b>Frage</b> 
                    </p>
                </div>
                <div class="question-cols rating-header">
                    <div class="rotated-text">Relevanz</div>
                </div>
                <div class="question-cols rating-header">
                    <div class="rotated-text">Ist-Digitalisierungs-<br>grad</div>
                </div>
                <div class="question-cols rating-header">
                    <div class="rotated-text">Digitalisierungsgrad<br>in 3 Jahren</div>
                </div>
                <div class="question-cols rating-header">
                    <div class="rotated-text">Handlungsbedarf</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'rgPdfQuestionDetailIntroduction',
    data: function() {
        return {};
    },
    computed: {
    },
    components: {

    },
}
</script>

<style scoped>
.parent-question-wrapper {
    display: flex;
    flex-direction: row;

}
.question-cols {
    display: flex;
    flex: 1;

    height: 200px;

    align-items: center;
    text-align: center;
    justify-content: center;

    font-weight: bold;

    border-bottom: 1px solid var(--color-dark);
}
.question-spacer {
    width: 50%;

    border-bottom: 1px solid var(--color-dark);
}
.question-name {
    display: flex;
    align-items: center;
}
.question-text {
    /*
    font-size: .95em;   
    
    padding-right: .5em;

    margin-bottom: .75em;
    */

    color: var(--color-dark);
    font-weight: bold;
}
.rating-header {
    border-left: 2px solid var(--color-dark);
}
.rotated-text {
    position: absolute;

    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}
</style>