// Import packages
import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import App                      from './App.vue'
import router                   from './config/router'
import pinia                    from './config/rootStore'
import auth                     from '@websanova/vue-auth/dist/v2/vue-auth.esm'
import Vuelidate                from 'vuelidate'
import vueAuthBody              from './config/vue-auth-body'
import VueSessionStorage        from 'vue-sessionstorage'
import VueScrollTo              from 'vue-scrollto'
import driverHttpAxios          from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm"
import driverRouterVueRouter    from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm"

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import { MdDialog, MdField, MdTooltip, MdSwitch, MdDialogAlert, MdButton, MdRadio } from 'vue-material/dist/components';

Vue.use(MdDialog);
Vue.use(MdDialogAlert);
Vue.use(MdField);
Vue.use(MdTooltip);
Vue.use(MdSwitch);
Vue.use(MdButton);
Vue.use(MdRadio);

Vue.use(VueSessionStorage);
Vue.use(Vuelidate);

Vue.config.productionTip = false

// Add VueScrollTo
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: false,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

// Add axios
Vue.use(
  VueAxios,
  axios,
)
Vue.axios.defaults.baseURL = (process.env.VUE_APP_API_URL) ? (process.env.VUE_APP_API_URL) : ('https://reifegradanalyse.hnu.de/api');

// Add vue-auth
Vue.router = router
Vue.use(auth, {
  plugins: {
      http: axios,
      router: router,
  },
  drivers: {
      auth: vueAuthBody,
      http: driverHttpAxios,
      router: driverRouterVueRouter,
  },
  options: {
      rolesKey: 'role',
      stores: ['storage', 'cookie'],
      refreshData: false,
      authRedirect: { path: '/login' },
      notFoundRedirect: { path: '/404'},
      forbiddenRedirect: { path: '/403' },
  }
})

// Create new vue instance
new Vue({
  render: h => h(App),
  router,
  pinia,
  validations: {},
})
.$mount('#app')