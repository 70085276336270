<template>
    <div
        class="question-group-wrapper"
    >
        <div class="question-group-container">
            <h4
                class="question-group-headline"
                v-bind:class="(groupSelected) ? ('question-group-headline-active') : ('')"
                v-on:click="emitGroupSelection(questionGroup.id)"
            >
                <rg-question-group-milestone
                    v-bind:displaycolor="displaycolor"
                    v-bind:questionGroup="questionGroup"
                ></rg-question-group-milestone>
                <span class="question-group-headline-title">{{ questionGroup.name }}</span>
                <span class="question-group-headline-progress" v-if="!groupSelected"><md-icon class="question-group-button-icon">keyboard_arrow_down</md-icon> Ausklappen</span>
                <div class="clearfix"></div>
            </h4>
            <Transition name="slide">
                <div
                    class="question-group-body"
                    v-if="groupSelected && questionsOrdered.length > 0"
                >
                    <rg-question-group-table-head-results
                        v-bind:visibility="visibility"
                    ></rg-question-group-table-head-results>
                    <rg-question-results
                        v-for="question in questionsOrdered"
                        v-bind:key="question.ident"
                        v-bind:question="question"
                        v-bind:displaycolor="displaycolor"
                        v-bind:isSubquestion="false"
                        v-bind:assessment="assessment"
                        v-on:answerStatusChanged="emitAnswerStatus"
                        v-bind:visibility="visibility"
                    ></rg-question-results>
                    <rg-question-group-buttons-results
                        v-bind:previousQuestionGroupId="previousQuestionGroupId"
                        v-bind:nextQuestionGroupId="nextQuestionGroupId"
                        v-on:questionGroupSelected="emitGroupSelection"
                    ></rg-question-group-buttons-results>
                </div>
                <div
                    class="question-group-body"
                    v-else-if="groupSelected && !questionsOrdered.length > 0"
                >
                    Diese Fragengruppe enthält derzeit keine Fragen.
                    <div class="space-30"></div>
                    <rg-question-group-buttons-results
                        v-bind:previousQuestionGroupId="previousQuestionGroupId"
                        v-bind:nextQuestionGroupId="nextQuestionGroupId"
                        v-on:questionGroupSelected="emitGroupSelection"
                    ></rg-question-group-buttons-results>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import rgQuestionGroupMilestone from './rgQuestionGroupMilestone.vue';
import { mapStores } from 'pinia'
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';
import RgQuestionGroupTableHeadResults from './rgQuestionGroupTableHeadResults.vue';
import RgQuestionResults from './rgQuestionResults.vue';
import RgQuestionGroupButtonsResults from './rgQuestionGroupButtonsResults.vue';


export default {
    name: 'rgQuestionGroupResults',
    data: function() {
        return {};
    },
    computed: {
        groupSelected: function() {
            return this.selectedGroup === this.questionGroup.id;
        },
        questionsOrdered: function() {
            return _.orderBy(_.filter(this.questionGroup.questions, function(o) { return o.active }), 'sortindex');
        },
        questionsTotal: function() {
            return _.filter(this.questionsOrdered, function(o) { return o.subquestions.length == 0 && o.active }).length + _.flatMap(this.questionsOrdered, function(o) { return o.subquestions }).length;
        },
        ...mapStores(useSurveyStore, useAssessmentStore),
    },
    methods: {
        emitGroupSelection: function(selectedGroupId) {
            this.$emit('groupSelected', selectedGroupId);
        },
        emitAnswerStatus: function(status) {
            this.$emit('answerStatusChanged', {
                questionId: status.questionId,
                answerStatus: status.answerStatus,
            });
        },
    },
    props: {
        questionGroup: {
            type: Object,
        },
        displaycolor: {
            type: String,
            required: true,
        },
        selectedGroup: {
            type: String,
            required: true,
        },
        assessment: {
            required: true,
            type: Object,
        },
        previousQuestionGroupId: {
            required: false,
            type: String,
        },
        nextQuestionGroupId: {
            required: false,
            type: String,
        },
        visibility: {
            type: Object,
            required: true,
        },
    },
    components: { rgQuestionGroupMilestone, RgQuestionGroupTableHeadResults, RgQuestionResults, RgQuestionGroupButtonsResults },
}
</script>

<style scoped>
.question-group-container,
        RgQuestionGroupButtonsResults {
    margin-left: 2em;
    padding: .25em 0 .25em;

    border-bottom: 2px solid var(--color-dark);
}
.question-group-headline {
    font-weight: normal;

    text-transform: initial;

    color: var(--color-dark);

    margin-top: 1.4em;
    margin-bottom: 1.4em;

    cursor: pointer;

}
.question-group-headline-title {
    float: left;
}
.question-group-headline-progress {
    float: right;
}
.question-group-headline-active {
    font-weight: bold;
}
</style>