<template>
    <div class="icon-tile-wrapper">
        <div
            class="icon-tile"
            v-bind:style="cssVars"
        >
            <img
                v-bind:src="iconSrc"
                alt="icon"
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'rgIconTile',
    data: function() {
        return {

        };
    },
    methods: {

    },
    computed: {
        cssVars: function() {
            let cssVars = {
                '--rg-icon-tile-color': this.color
            };
            if(this.tileColor) cssVars['background-color'] = this.tileColor;
            return cssVars;
        },
    },
    props: {
        color: {
            type: String,
            required: true
        },
        iconSrc: {
            type: String,
            required: true
        },
        tileColor: {
            type: String,
            required: false,
        },
    },
}
</script>

<style scoped>
.icon-tile-wrapper {
    position: relative;

    margin: 0;
    z-index: 1;
}
.icon-tile {
    position: relative;
    top: 1.5em;
    left: 1.5em;

    width: 9em;
    height: 9em;
    
    padding: .75em;
    margin-bottom: 1.5em;

    background-color: var(--color-gray);
}
.icon-tile:after {
    content: "";

    position: absolute;
    top: -1.5em;
    left: -1.5em;
    z-index: -1;

    width: 9em;
    height: 9em;

    background: var(--rg-icon-tile-color);
}

</style>