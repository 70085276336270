<template>
    <div>
        <div class="admin-dashboard-hero-wrapper">
            <div class="admin-dashboard-hero container">
                <div class="row">
                    <div class="col-12">
                        <h3>Willkommen auf Ihrem Reifegrad-Dashboard, {{_user.firstname}} {{_user.lastname}}!</h3>
                        <p>Hier können Sie die Inhalte des Systems anpassen.</p>
                        <rg-button styletype="color-primary">Mein Profil</rg-button>
                        <rg-button styletype="color-primary">Zum Reifegradtool</rg-button>
                    </div>
                </div>
            </div>            
        </div>
        <div class="space-30"></div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3>Dashboard</h3>
                    <p>Hier finden Sie eine Übersicht der Funktionen, die Ihnen aktuell zur Verfügung stehen.</p>
                </div>
            </div>
            <div class="row">
                <div
                    class="col-6"
                    v-for="(tile, index) in tiles"
                    v-bind:key="index"
                >
                    <action-tile
                        v-bind:icon="tile.icon"
                        v-bind:headline="tile.headline"
                        v-bind:description="tile.description"
                        v-bind:link="tile.link"
                    ></action-tile>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActionTile from '../../gui/admin/ActionTile.vue';
import rgButton from '../../gui/app/rgButton.vue';

export default {
    name: 'AdminDashboard',
    data: () => {
        return {
            tiles: [
                {
                    icon: 'icofont-unique-idea',
                    headline: 'Umfragen bearbeiten',
                    description: 'Sie können neue Umfragen hinzufügen, bestehende Umragen löschen oder deaktivieren.',
                    link: '/admin/surveys',
                },
                {
                    icon: 'icofont-question-circle',
                    headline: 'Fragen bearbeiten',
                    description: 'Sie können neue Fragen hinzufügen, bestehende Fragen bearbeiten, löschen oder deaktivieren.',
                    link: '/admin/questions',
                },
                {
                    icon: 'icofont-male',
                    headline: 'Benutzer bearbeiten',
                    description: 'Sie können neue Benutzer hinzufügen, bestehende Benutzer bearbeiten, löschen oder deaktivieren.',
                    link: '/admin/users',
                },
                {
                    icon: 'icofont-settings',
                    headline: 'Einstellungen bearbeiten',
                    description: 'Sie können neue Benutzer hinzufügen, bestehende Benutzer bearbeiten, löschen oder deaktivieren.',
                    link: '/admin/settings',
                }
            ]
        };
    },
    computed: {
        _user() {
            return this.$auth.user() || {};
        },
    },
    components: { rgButton, ActionTile },
}
</script>

<style scoped>
.admin-dashboard-hero-wrapper {
    background-color: #EDEDED;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1161' height='139.3' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23D5D5D5' stroke-width='1' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
}
.admin-dashboard-hero {
    padding-top: 2.5em;
    padding-bottom: 5.5em;

    color: var(--color-text);
    font-size: var(--);
}
</style>