<template> 
    <div class="further-action-wrapper">
        <rg-text-block>
            <template v-slot:headline>
                Weiteres Vorgehen
            </template>
            <template v-slot:content>
                Die Ergebnisse aus der Analyse unseres Reifegrad-Onlinetools 2.0 zeigen Ihre Sicht auf die Digitalisierung Ihres Unternehmens nun klarer auf.
                Aus den vorliegenden Erkenntnissen lässt sich nun eine Digitalisierungsstrategie und Roadmap für Ihr Ihren Weg in die Digitale Transformation entwickeln.
                <br><br>
                Gerne unterstützen wir Sie bei der Erarbeitung Ihrer Digitalisierungsstrategie. Wenden Sie sich hierzu an das IDT-Team!
                <br><br><br>
                <rg-button v-on:click="openLink">Kontakt aufnehmen</rg-button>
                <br><br>
                <i>„Digitale Zukunft erforschen, erklären und gestalten“</i>
            </template>
        </rg-text-block>
    </div>
</template>

<script>
import rgButton from './rgButton.vue'
import rgTextBlock from './rgTextBlock.vue'
export default {
    methods: {
        openLink: function() {
            window.open('https://www.hnu.de/forschung/institute-kompetenzzentren/institut-fuer-digitale-transformation-idt/team', '_blank').focus();
        },
    },
    components: { rgTextBlock, rgButton },
}
</script>

<style>

</style>