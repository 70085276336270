<template>
    <button
        class="rg-button color-transition"
        v-bind:class="[styletype, animationtype]"
        v-on:click='$emit("click", $event.target.value)'
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'rgHeader',
    data: () => {
        return {};
    },
    computed: {
        _user() {
            return this.$auth.user() || {};
        },
    },
    methods: {
    },
    props: {
        styletype: {
            type: String,
            required: false,
        },
        animationtype: {
            type: String,
            required: false,
        }
    }
}
</script>

<style scoped>
/* Default button styles */
.rg-button {
    padding: .4em 1.2em .4em 1.2em;
    margin-right: .5em;
    margin-bottom: .5em;

    border: 1px solid;

    background: none;
    cursor: pointer;

    font-size: var(--font-size-button);
}
.color-transition:HOVER {
    -webkit-transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
    -moz-transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
    -o-transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
    transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
}

/* Button styles */
.color-light {
    color: var(--color-light);
}
.color-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
}
.color-primary:HOVER {
    color: var(--color-light);
    background: var(--color-primary);
    border-color: var(--color-primary);
}

/* Animation styles */
.animation-light {

}
.animation-secondary {

}

</style>