<template>
    <div>
        <page-header>
            <h3>Umfragenverwaltung</h3>
            <p>In diesem Bereich können Sie neue Umfragen hinzufügen, bestehende Umfragen bearbeiten, löschen oder deaktivieren.</p>
        </page-header>
        <list-wizard
            v-bind:config="config"
        ></list-wizard>
    </div>
</template>

<script>
import ListWizard from '../../gui/admin/ListWizard.vue';
import PageHeader from '../../gui/admin/PageHeader.vue';

export default {
    name: 'AdminUsers',
    data: function() {
        return {
            config: {
                nameSingular: 'Umfrage',
                namePlural: 'Umfragen',
                apiRoute: '/surveys',
                displayAttributes: [
                    {
                        ident: 'title',
                        name: 'Titel'
                    },
                    {
                        ident: 'description',
                        name: 'Beschreibung'
                    },
                ]
            }
        };
    },
    components: { PageHeader, ListWizard },
}
</script>

<style>

</style>