<template>
    <section class="pdf-item">
        <div class="pdf-wrapper">
            <h2 class="pdf-headline">(2) Top 10 Handlungsbedarf</h2>
            <p>
                Wo greifen Sie als erstes an in der Digitalisierung Ihres Unternehmens?
                Dies zeigen Ihnen die TOP 10 Ihrer Handlungsbedarfe, denn hier haben Sie einerseits
                die höchste Relevanz aus Unternehmenssicht, andererseits die größte Differenz
                zwischen SOLL- und IST-Zustand.
            </p>
        </div>
    </section>
</template>

<script>

export default {
    name: 'rgTopTenResultsPdfIntroduction',
    data: function() {
        return {};
    },
    computed: {
    },
    components: {

    },
}
</script>

<style scoped>

</style>