<template>
    <div class="bars-wrapper pdf-wrapper">
        <h2 class="pdf-headline">Reifegrad nach den fünf Handlungsfeldern</h2>
        <p>
            Nachfolgend Sie einen Überblick über Ihren aktuellen <b>Ist-Digitalisierungsgrad, Soll-Digitalisierungsgrad</b> und den <b>Handlungsbedarf</b> je Handlungsfeld.
            <br>
            <br>
            Skala von 0-3 (0 = niedrig, 3 = hoch)
        </p>
        <div class="space-50"></div>
        <div class="bars-container">
            <div class="bars-row">
                <div class="bars-col bar-header">Handlungsfeld</div>
                <div class="bars-col bar-header">Reifegrad heute</div>
                <div class="bars-col bar-header">Reifegrad in 3 Jahren</div>
                <div class="bars-col bar-header">Handlungsbedarf</div>
            </div>
            <div class="bars-row" v-for="category, i in displayCategories" v-bind:key="i">
                <div class="bars-col score-bar-label">{{ category.name }}</div>
                <div class="bars-col score-bar-wrapper">
                    <rg-score-bar
                        class="score-bar"
                        v-bind:color="'gray'"
                        v-bind:score="(Math.round(assessmentStore.getScoreByQuestionCategory('valueToday', category.ident) * 100)*3)/100"
                        v-bind:maxWidthInPx="80"
                        v-bind:heightInEm="2"
                    ></rg-score-bar>
                </div>
                <div class="bars-col score-bar-wrapper">
                    <rg-score-bar
                        class="score-bar"
                        v-bind:color="'#425CA6'"
                        v-bind:score="(Math.round(assessmentStore.getScoreByQuestionCategory('valueFuture', category.ident) * 100)*3)/100"
                        v-bind:maxWidthInPx="80"
                        v-bind:heightInEm="2"
                    ></rg-score-bar>
                </div>
                <div class="bars-col score-bar-wrapper">
                    <rg-score-bar
                        class="score-bar"
                        v-bind:color="'#FF5252'"
                        v-bind:score="(Math.round(assessmentStore.getScoreByQuestionCategory('valueFuture', category.ident) * 100)*3)/100 - (Math.round(assessmentStore.getScoreByQuestionCategory('valueToday', category.ident) * 100)*3)/100"
                        v-bind:maxWidthInPx="80"
                        v-bind:heightInEm="2"
                    ></rg-score-bar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../../config/assessmentStore';
import { useSurveyStore } from '../../../../config/surveyStore';
import RgScoreBar from '../rgScoreBar.vue';

export default {
    name: 'rgBarsOverallResults',
    data: function() {
        return {
            displayCategories: [
                {
                    ident: 'category-1',
                    name: 'A: Strategie & Geschäftsmodell',
                },
                {
                    ident: 'category-2',
                    name: 'B: Partnerschnittstelle',
                },
                {
                    ident: 'category-3',
                    name: 'C: Unternehmen',
                },
                {
                    ident: 'category-4',
                    name: 'D: Produkte & Services',
                },
                {
                    ident: 'category-5',
                    name: 'E: Kundenschnittstelle',
                },
            ],
        };
    },
    methods :{
    },
    computed: {
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    components: { RgScoreBar }
}
</script>

<style scoped>
.bars-container {
    justify-content: center;
}
.bars-row {
    display: flex;
    justify-content: left;

    padding-top: .5em;
    padding-bottom: .5em;
}
.bars-col {
    display: flex;
    justify-content: left;
    align-items: center;

    flex: 1 0 0;
} 
.score-bar-label {
    padding-right: 2em;

    justify-content: right;
    text-align: right;
}
.bar-header:first-child {
    padding-right: 2em;

    justify-content: right;
}
.bar-header {
    font-weight: bold;

    justify-content: left;

    flex: 1 0 0;
}
.score-bar-wrapper {
    display: flex;
    justify-content: left;
}

</style>