<template>
    <div class="page-wrapper" v-if="surveyStore.survey !== null && assessmentStore.assessment !== null">
        <rg-header-results></rg-header-results>
        <rg-bars-overall-results></rg-bars-overall-results>
        <rg-house-results></rg-house-results>
        <rg-bars-category-results></rg-bars-category-results>
        <rg-top-ten-results></rg-top-ten-results>
        <rg-detail-results></rg-detail-results>
        <rg-further-action-results></rg-further-action-results>
        <rg-references-results></rg-references-results>
        <rg-footer></rg-footer>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';
import RgDetailResults from '../../gui/app/rgDetailResults.vue';
import RgFooter from '../../gui/app/rgFooter.vue';
import RgHeaderResults from '../../gui/app/rgHeaderResults.vue';
import RgHouseResults from '../../gui/app/rgHouseResults.vue';
import RgTopTenResults from '../../gui/app/rgTopTenResults.vue';
import RgFurtherActionResults from '../../gui/app/rgFurtherActionResults.vue';
import RgReferencesResults from '../../gui/app/rgReferencesResults.vue';
import RgBarsOverallResults from '../../gui/app/rgBarsOverallResults.vue';
import RgBarsCategoryResults from '../../gui/app/rgBarsCategoryResults.vue';

export default {
    name: 'Analysis',
    data: function() {
        return {};
    },
    computed: {
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    created: function() {
        // TODO: FIND SOLUTION IF USER ACCESSES RESULTS DIRECTLY ??? HOW TO REDIRECT TO /analysis ON ILLEGAL ACCESS BUT NOT ON REFRESH?
        window.scrollTo(0, 0);
    },
    components: { RgHeaderResults, RgHouseResults, RgFooter, RgTopTenResults, RgDetailResults, RgFurtherActionResults, RgReferencesResults, RgBarsOverallResults, RgBarsCategoryResults },
}
</script>

<style>

</style>