<template>
    <footer class="rg-footer">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="space-50"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="footer-col img-col" style="width: 30%">
                        <img src="../../../assets/img/logo-hnu-white.png" class="logo-hnu" />
                    </div>
                    <div class="footer-col img-col" style="width: 12.5%">
                        <img src="../../../assets/img/logo-innosued-white.png" class="logo-innosued" />
                    </div>
                    <div class="footer-col"  style="width: 32.5%">
                        <p>
                            Institut für Digitale Transformation<br>
                            Wileystraße 1<br>
                            89231 Neu-Ulm<br>
                        </p>
                    </div>
                    <div class="footer-col" style="width: 25%">
                        <p>
                            Mail: <a href="mailto:info@hnu.de" class="footer-link">info@hnu.de</a><br>
                            Web: <a href="https://hnu.de/idt" class="footer-link">www.hnu.de/idt</a><br>
                            <br>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="space-30"></div>
                </div>
            </div>
            <div class="row footer-subnav-wrapper">
                <div class="col-4">
                    <p>
                        <a
                            v-for="(socialLink, index) in socialLinks"
                            v-bind:key="index"
                            v-bind:href="socialLink.url"
                            class="footer-social-link"
                        >
                            <img v-bind:src="socialLink.icon" v-bind:alt="socialLink.name" />
                        </a>
                    </p>
                </div>
                <div class="col-8">
                    <ul class="footer-subnav">
                        <li
                            v-for="(navEntry, index) in footerLinks"
                            v-bind:key="index">
                                <a target="_blank" v-bind:href="navEntry.url">{{ navEntry.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="space-123"></div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'rgFooter',
    data: function() {
        return {
            footerLinks: [
                {
                    name: 'Kontakt',
                    url: 'https://www.hnu.de/kontaktform',
                },
                {
                    name: 'Impressum',
                    url: 'https://www.hnu.de/impressum',
                },
                {
                    name: 'Datenschutz',
                    url: 'https://www.hnu.de/datenschutzerklaerung',
                },
                {
                    name: 'Barrierefreiheit',
                    url: 'https://www.hnu.de/barrierefreiheit',
                },
            ],
            socialLinks: [
                {
                    name: 'Xing',
                    icon: require('../../../assets/img/icons/xing.png'),
                    url: 'https://www.xing.com/pages/institutfurdigitaletransformation',
                },
                {
                    name: 'LinkedIn',
                    icon: require('../../../assets/img/icons/linkedin.png'),
                    url: 'https://de.linkedin.com/company/institut-fuer-digitale-transformation',
                },
                {
                    name: 'Youtube',
                    icon: require('../../../assets/img/icons/youtube.png'),
                    url: 'https://www.youtube.com/channel/UCHqzy2a3krCs7PaZcIakhFw',
                },
            ],
        };
    },

}
</script>

<style scoped>
.rg-footer {
    position: relative;

    width: 100%;
}
.rg-footer::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-image: url('../../../assets/img/footer.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    background-color: #000000;

    /*
    -moz-filter: grayscale(1);
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    */
}
.logo-hnu {
    width: 100%;
    height: auto;

    padding-right: 1em;
}
.logo-innosued {
    width: 100%;
    height: auto;

    padding: 0 1em 0 1em;
}
.footer-col {
    height: 10em;
    display: flex;

    float: left;

    align-items: center;
    justify-content: right;
}
.footer-col p {
    align-items: center;

    margin: 0;

    color: var(--color-light);
}
.footer-link {
    display: inline;
    color: var(--color-light) !important;
}
.footer-subnav-wrapper {
    border-top: 3px solid var(--color-light);
}
.footer-subnav {
    margin: 0;
    float: right;
    margin-top: 20px;

    list-style: none;
}
.footer-subnav li {
    float: left;

    margin-left: 1em;
}
.footer-subnav li a {
    color: var(--color-light);
}
.footer-subnav li a:HOVER {
    color: var(--color-light);
}
.footer-social-link {
    display: block;

    width: 3em;
    margin-right: .75em;

    float: left;
}
</style>