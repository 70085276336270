<template>
    <div class="container">

        <!-- Introduction and "Create new"-Button -->
        <div class="row">
            <div class="col-12">
                <h3>{{ config.nameSingular }}</h3>
                <p>Nachfolgend sehen Sie eine Liste aller, im System vorhandener {{ config.namePlural }}.</p>
                <div
                    v-if="elements.length !== 0"
                    class="interaction-elements-wrapper">
                    <div class="add-new-button-wrapper">
                        <rg-button
                            styletype="color-primary"
                            v-on:click="openCreateOrUpdate"
                        >
                            {{ config.nameSingular }} hinzufügen
                        </rg-button>
                    </div>
                    <div class="search-field-wrapper">
                        <!--
                        <md-field md-clearable>
                            <label>Suche</label>
                            <md-input
                                v-model="searchText"
                                v-on:keyup="searchElements"
                            ></md-input>
                        </md-field>
                        -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="row">
            <div class="col-12">
                <div class="space-30"></div>
            </div>
        </div>

        <!-- Element list -->
        <div class="row">
            <div class="col-12">

                <div
                    class="list-table-wrapper"
                    v-if="elements.length !== 0"
                >
                        
                    <!-- Table head -->
                    <div
                        class="list-table-head"
                    >
                        <div
                            v-for="(column, index) in config.displayAttributes"
                            v-bind:key="index"
                            class="list-table-head-cols"
                        >
                            <a
                                v-on:click="setSortOrder(column.ident)"
                                class="list-table-head-cols-names"
                            >
                                {{ column.name }} &nbsp; <i class="icofont-sort sort-icon"></i>
                            </a>
                        </div>
                        <div class="list-table-head-cols">Aktionen</div>
                    </div>

                    <!-- Table body -->
                    <div
                        class="list-table-entry"
                        v-for="(element, index) in elements"
                        v-bind:key="index"
                    >
                        <div
                            v-for="(column, index) in config.displayAttributes"
                            v-bind:key="index"
                            class="list-table-entry-cols"
                        >
                            {{ element[column.ident] }}
                        </div>
                        <div class="list-table-entry-cols">
                            <a class="action-button" v-on:click="openView(element.id)"><span class="material-icons">visibility</span></a>
                            <a class="action-button" v-on:click="openCreateOrUpdate(element.id)"><span class="material-icons">mode_edit</span></a>
                            <a class="action-button" v-on:click="openDelete(element.id)"><span class="material-icons">delete</span></a>
                        </div>
                    </div>

                </div>
                <div
                    v-else
                    class="empty-list-notification"
                >
                    <div>
                        <p>
                            <span class="material-icons list-empty-icon">
                                playlist_remove
                            </span>
                            <br>
                            In dieser Ansicht können keine Elemente angezeigt werden.
                            <br>
                            Fügen Sie jetzt den ersten Eintrag hinzu.
                        </p>
                        <div class="space-5"></div>
                        <rg-button
                            styletype="color-primary"
                            v-on:click="openCreateOrUpdate()"
                        >
                            {{ config.nameSingular }} hinzufügen
                        </rg-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Interaction Modal -->
        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title><h3>{{ config.nameSingular }} {{ dialogActionName }}</h3></md-dialog-title>
            <component
                v-bind:is="dialogComponentName"
                v-bind:currentElement="currentElement"
                v-on:closeModal="closeModal"
            ></component>
        </md-dialog>
    </div>
</template>

<script>
// Organize imports
import rgButton from '../app/rgButton.vue';
import arraySort from 'array-sort';

export default {
    name: 'ListWizard',
    data: function() {
        return {
            elements: [],
            currentElement: null,
            sorting: {
                sortBy: null,
                sortDirection: null,
            },
            searchText: null,
            showDialog: false,
            dialogActionName: null,
            dialogComponentName: null,
        };
    },
    methods: {
        // CRUD-methods
        getElements: function() {
            return this.$http
                .get(this.config.apiRoute)
                .then((response) => {
                    this.elements = response.data;
                })
                .catch((error) => {
                    // TODO: implement error handling
                    console.log(error);
                });
        },
        getElement: function(id) {
            return this.$http
                .get(this.config.apiRoute + '/' + id)
                .then((response) => {
                    this.currentElement = response.data;
                })
                .catch((error) => {
                    // TODO: implement error handling
                    console.log(error);
                });
        },
        createElement: function() {},
        updateElement: function() {},
        deleteElement: function() {},

        // Modal functions
        openView: function(id) {
            // Configure dialog component properties
            this.dialogActionName = 'anzeigen';
            this.dialogComponentName = this.config.dialogComponentName.view;
            // Retrieve element from server and provide it to the dialog component
            this.getElement(id)
                .then(() => {
                    this.openModal(id);
                });
        },
        openCreateOrUpdate: function(id) {
            this.dialogActionName = 'bearbeiten';
            this.dialogComponentName = this.config.dialogComponentName.edit;
            this.openModal(id);
        },
        openDelete: function(id) {
            this.dialogActionName = 'löschen';
            this.dialogComponentName = this.config.dialogComponentName.delete;
            this.openModal(id);
        },
        openModal: function() {
            this.showDialog = true;
        },
        closeModal: function() {
            // Close modal
            this.showDialog = false;
            // Unset current element
            this.currentElement = null;
        },

        // Search and filter functions
        searchElements: function() {
            // TODO: implement search function
            console.log(this.searchText);
            console.log(this.elements.filter(function(element) {
                return element;        
            }));
        },
        sortElements: function() {
            arraySort(this.elements, this.sorting.sortBy, { reverse: (this.sorting.sortDirection === 'asc') ? (false) : (true) });
        },
        setSortOrder: function(ident) {
            this.sorting.sortBy = ident;
            this.sorting.sortDirection = (this.sorting.sortDirection === 'asc') ? 'desc' : 'asc';
            this.sortElements();
        },
    },
    created: function() {
        this.getElements().then(() => {
            // Initially sort elements by first column
            if(Array.isArray(this.config.displayAttributes)) {
                this.setSortOrder(this.config.displayAttributes[0].ident);
            }
        });
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    components: { rgButton },
}
</script>

<style scoped>
.interaction-elements-wrapper {
    display: flex;
}
.add-new-button-wrapper {
    flex: 1 1 0;
}
.search-field-wrapper {
    flex: 1 1 0;
    text-align: end;
}
.list-table-wrapper {
}
.list-table-head {
    display: flex;

    padding: 0 1em .5em 1em;
    font-weight: bold;
}
.list-table-head .list-table-head-cols {
    flex: 1 1 0;
}
.list-table-head-cols a {
    cursor: pointer;
    color: inherit !important;
    text-decoration: none !important;
}
.list-table-entry {
    display: flex;

    justify-content: center;
    align-items: center;

    margin-top: .5em;
    padding: 1em;
    background: var(--color-gray);
}
.list-table-entry .list-table-entry-cols {
    flex: 1 1 0;

    padding-right: .5em;

    word-break: break-all;
}
.sort-icon {
    font-size: .75em;
}
.action-button {
    display: flex;
    float: left;

    justify-content: center;
    align-items: center;

    width: 1.7em;
    height: 1.7em;
    margin: .2em;

    color: var(--color-primary) !important;
    /* background: #BBBBBB; */
    border: 1px solid var(--color-primary);
    text-decoration: none !important;

    cursor: pointer;
}
.action-button:HOVER {
    background: var(--color-primary);
    color: var(--color-light) !important;

    -webkit-transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
    -moz-transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
    -o-transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
    transition: background 0.3s 0s ease-in-out, color 0.3s 0s ease-in-out;
}
.empty-list-notification {
    text-align: center;
}
.list-empty-icon {
    font-size: 6em;
}

</style>