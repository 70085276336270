<template>
    <div class="md-dialog-content dialog-content-wrapper container">
        <div class="row">
            <!--
            <div class="col-12 no-gutters">
                Benutzer {{ currentElement.firstname }} {{ currentElement.lastname }} ({{ currentElement.id }})
                <div class="space-30"></div>
            </div>
            -->
        </div>
        <div class="row">
            <div class="col-6 no-gutters">
                <p class="half-left">
                    <span class="attribute-label">Name</span>
                    <span class="attribute-content">{{ currentElement.firstname }} {{ currentElement.lastname }}</span>
                </p>
            </div>
            <div class="col-6 no-gutters">
                <p class="half-right">
                    <span class="attribute-label">Rolle</span>
                    <span class="attribute-content">{{ role }}</span>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 no-gutters">
                <p>
                    <span class="attribute-label">E-Mail</span>
                    <span class="attribute-content">{{ currentElement.email }}</span>
                 </p>
            </div>
        </div>
        <div class="space-40"></div>
        <rg-button
            v-bind:styletype="'color-primary'"
            v-on:click="$emit('closeModal')"
        >Ok</rg-button>
    </div>
</template>

<script>
import rgButton from '../../../gui/app/rgButton.vue';
export default {
  components: { rgButton },
    name: 'AdminUsersView',
    data: function() {
        return {
        };
    },
    props: {
        currentElement: {
            type: Object,
            required: true,
        }
    },
    computed: {
        role: function() {
            const roles = {
                'administrator': 'Administrator'
            };

            return roles[this.currentElement.role]
        }
    },
}
</script>

<style>
.dialog-content-wrapper {
    min-width: 40em;
}
.attribute-label {
    display: block;
    margin-bottom: .5em
}
.attribute-content {
    display: block;

    font-weight: bold;
}

</style>