import { defineStore } from 'pinia';
import _ from 'lodash';

export const useSurveyStore = defineStore('survey', {
    state: () => {
        return {
            survey: null,
        };
    },
    getters: {
        getActiveQuestions: function() {
            // TODO: MAYBE REORGANIZE? MIGHT BE INPERFORMANT - DEPENDING ON FINAL DECISION ON ORDER MECHANISM
            let activeQuestions = [];
            _.sortBy(this.survey.questioncategories.filter((questioncategory) => { return questioncategory.active }), ['sortindex']).forEach((questionCategory) => {
                if(_.has(questionCategory, 'questiongroups')) {
                    _.sortBy(questionCategory.questiongroups.filter((questiongroup) => { return questiongroup.active }), ['sortindex']).forEach((questiongroup) => {
                        if(_.has(questiongroup, 'questions')) {
                            _.sortBy(questiongroup.questions.filter((question) => { return question.active }), ['sortindex']).forEach((question) => {
                                activeQuestions.push(question);
                            });        
                        }
                    });
                }
            });
            return activeQuestions;
        },
        getQuestionIndexById: function() {
            return (questionId) => {
                const _this = this;
                return _.findIndex(_this.getActiveQuestions, function(o) { return o.id === questionId });
            }
        },
        getQuestionBySubquestion: function() {
            return (subquestionId) => {
                const _this = this;
                return _.find(_this.getActiveQuestions, (o) => {
                    if(!_this._hasSubquestions(o)) return false;
                    let result = false;
                    result = _.find(o.subquestions, (subquestion) => {
                        return subquestion.id === subquestionId;
                    });
                    return !!result;
                });
            };
        },
        getQuestionsByQuestiongroup: function() {
            return (questionGroupId) => {
                return this.getQuestiongroup(questionGroupId).questions.filter((question) => {
                    return question.active;
                });
            };
        },
        getQuestionsByQuestioncategory: function() {
            return (questionCategoryId) => {
                const _this = this;
                let questions = [];
                this.getQuestiongroupsByQuestioncategory(questionCategoryId).forEach((questionGroup) => {
                    _this.getQuestionsByQuestiongroup(questionGroup.id).forEach((question) => {
                        if(question.active) questions.push(question);
                    });
                });
                return questions;
            }
        },
        getQuestionsByQuestioncategoryIdent: function() {
            return (questionCategoryIdent) => {
                const _this = this;
                let questions = [];
                this.getQuestiongroupsByQuestioncategoryIdent(questionCategoryIdent).forEach((questionGroup) => {
                    _this.getQuestionsByQuestiongroup(questionGroup.id).forEach((question) => {
                        if(question.active) questions.push(question);
                    });
                });
                return questions;
            }
        },
        getQuestiongroupsByQuestioncategory: function() {
            return (questionCategoryId) => {
                const questioncategory = _.find(this.survey.questioncategories, function(o) { return o.id === questionCategoryId });
                if(!questioncategory) return null;
                return _.filter(questioncategory.questiongroups, (o) => { return o.active });
            }
        },
        getQuestiongroupsByQuestioncategoryIdent: function() {
            return (questionCategoryIdent) => {
                const questioncategory = _.find(this.survey.questioncategories, function(o) { return o.ident === questionCategoryIdent });
                if(!questioncategory) return null;
                return _.filter(questioncategory.questiongroups, (o) => { return o.active });
            }
        },
        getQuestiongroup: function() {
            return (questionGroupId) => {
                for (let i = 0; i < this.survey.questioncategories.length; i++) {
                    for (let j = 0; j < this.survey.questioncategories[i].questiongroups.length; j++) {
                        if(this.survey.questioncategories[i].questiongroups[j].id === questionGroupId) {
                            return this.survey.questioncategories[i].questiongroups[j];
                        }
                    }                    
                }
                return [];
            };
        },
        getQuestiongroups: function() {
            const _this = this;
            return _.flatMap(_this.getQuestionCategories, (o) => {
                if(_.has(o, 'questiongroups')) return o.questiongroups;
                return [];
            });
        },
        getQuestiongroupByQuestion: function() {
            return (questionId) => {
                const _this = this;
                return _.find(_this.getQuestiongroups, (o) => {
                    if(!o.questions.length > 0) return false;
                    let result = false;
                    result = _.find(o.questions, (question) => {
                        return question.id === questionId;
                    });
                    return !!result;
                });
            };
        },
        getQuestionCategory: function() {
            return (questionCategoryId) => {
                return _.find(this.survey.questioncategories, function(o) { return o.id === questionCategoryId });
            };
        },
        getQuestionCategories: function() {
            return this.survey.questioncategories;
        },
        getQuestionCategoriesOrdered: function() {
            return _.orderBy(_.filter(this.survey.questioncategories, function(o) { return o.active }), 'sortindex');
        },
        getQuestionsCountByQuestioncategory: function() {
            return (questionCategoryId) => {
                return this._flattenQuestionsAndSubquestions(this.getQuestionsByQuestioncategory(questionCategoryId)).length;
            };
        },
        getQuestionsCountByQuestiongroup: function() {
            return (questionGroupId) => {
                return this._flattenQuestionsAndSubquestions(this.getQuestionsByQuestiongroup(questionGroupId)).length;
            };
        },
    },
    actions: {
        setSurvey: function(survey) {
            this.survey = survey;
        },
        _flattenQuestionsAndSubquestions: function(questions) {
            const _this = this;
            let flattenedQuestions = [];
            if(!_.isArray(questions)) return flattenedQuestions;
            questions.forEach((question) => {
                (_this._hasSubquestions(question)) ? (flattenedQuestions.push(question.subquestions)) : (flattenedQuestions.push(question));
            });
            return _.flatten(flattenedQuestions, true);
        },
        _flattenQuestionsAndSubquestionsForPdf: function(questions) {
            const _this = this;
            let flattenedQuestions = [];
            if(!_.isArray(questions)) return flattenedQuestions;
            questions.forEach((question) => {
                // (_this._hasSubquestions(question)) ? (flattenedQuestions.push(question.subquestions)) : (flattenedQuestions.push(question));
                
                flattenedQuestions.push(question);
                if(_this._hasSubquestions(question)) flattenedQuestions.push(question.subquestions);
            });
            return _.flatten(flattenedQuestions, true);
        },
        _orderEntitiesBySortindex: function(entities, onlyActive=true) {
            if(onlyActive) entities = _.filter(entities, function(o) { return o.active });
            return _.orderBy(entities, 'sortindex');
        },
        _hasSubquestions: function(question) {
            if(_.has(question, 'subquestions') && _.isArray(question.subquestions)) {
                if(question.subquestions.length > 0) {
                    return true;
                }
            }
            return false;
        },
        _isSubquestion: function(questionId) {
            const _this = this;
            console.log("INDEX", _.findIndex(_this.getActiveQuestions, function(o) { return o.id === questionId }));
            return !(_.findIndex(_this.getActiveQuestions, function(o) { return o.id === questionId }) >= 0);
        },
    }
});