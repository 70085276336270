<template>
    <div>
        <page-header>
            <h3>Benutzerverwaltung</h3>
            <p>In diesem Bereich können Sie neue Benutzer hinzufügen, bestehende Benutzer bearbeiten, löschen oder deaktivieren.</p>
        </page-header>
        <list-wizard
            v-bind:config="config"
        ></list-wizard>
    </div>
</template>

<script>
import ListWizard from '../../gui/admin/ListWizard.vue';
import PageHeader from '../../gui/admin/PageHeader.vue';

import AdminUsersView from './forms/AdminUsersView.vue';
import AdminUsersEdit from './forms/AdminUsersEdit.vue';
import AdminUsersDelete from './forms/AdminUsersDelete.vue';

export default {
    name: 'AdminUsers',
    data: function() {
        return {
            config: {
                nameSingular: 'Benutzer',
                namePlural: 'Benutzer',
                apiRoute: '/users',
                displayAttributes: [
                    {
                        ident: 'firstname',
                        name: 'Vorname'
                    },
                    {
                        ident: 'lastname',
                        name: 'Nachname'
                    },
                    {
                        ident: 'email',
                        name: 'E-Mail'
                    },
                ],
                dialogComponentName: {
                    view: AdminUsersView,
                    edit: AdminUsersEdit,
                    delete: AdminUsersDelete,
                }
            }
        };
    },
    components: { PageHeader, ListWizard },
}
</script>

<style>

</style>