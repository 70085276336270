<template>
  <div class="app-wrapper">
    <router-view></router-view>
    <md-dialog-alert
      v-bind:md-active.sync="errorModal.active"
      v-bind:md-title="errorModal.title"
      v-bind:md-content="errorModal.content"
      v-bind:md-confirm-text="errorModal.confirmText"
    ></md-dialog-alert>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../config/assessmentStore';
import { useSurveyStore } from '../../config/surveyStore';

export default {
  name: 'Home',
  data: function() {
    return {
      survey: null,
      savedAssessment: null,
      errorModal: {
          active: false,
          title: 'Fehler',
          content: '',
          confirmText: 'Okay',
      },
    };
  },
  methods: {
    getSurvey: function() {
      const _this = this;
      return this.$http
        .get('surveys/f5e0ea6a-525d-449e-a7b0-eaa885d7d391')
        .then((response) => {
          _this.survey = response.data;
          _this.setSurveyInStore(response.data);
        })
        .catch(() => {
          _this.openErrorModal('Beim Laden der Umfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');
        });
    },
      startAssessment: function() {
          if(!this.hasAssessmentLocally() && !this.savedAssessment) {
              this.createAssessment();
          } else {
              this.getAssessment();
          }
      },
      hasAssessmentLocally: function() {
          return this.$session.exists('assessmentId') && this.$session.exists('assessmentIdent')
      },
      setAssessmentLocally: function(assessmentId, assessmentIdent) {
          this.$session.set('assessmentId', assessmentId);
          this.$session.set('assessmentIdent', assessmentIdent);
      },
      setAssessmentInStore: function(assessment) {
          this.assessmentStore.setAssessment(assessment);
      },
      setSurveyInStore: function(survey) {
          this.surveyStore.setSurvey(survey);

      },
      getAssessment: function() {
          const _this = this;
          if(this.savedAssessment) {
              return this.$http
                  .get('assessments/getByIdent/' + this.savedAssessment)
                  .then((response) => {
                      _this.setAssessmentInStore(response.data);
                      _this.setAssessmentLocally(response.data.id, response.data.ident);
                  })
                  .catch(() => {
                      this.openErrorModal('Beim Laden Ihrer Sitzung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');                    
                  });
          } else {
              const assessmentId = this.$session.get('assessmentId');
              return this.$http
                  .get('assessments/' + assessmentId)
                  .then((response) => {
                      _this.setAssessmentInStore(response.data);
                  })
                  .catch(() => {
                      this.openErrorModal('Beim Laden Ihrer Sitzung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');                    
                  });
          }
      },
      createAssessment: function() {
          const _this = this;
          return this.$http
              .post('assessments', {})
              .then((response) => {
                  _this.setAssessmentInStore(response.data);
                  _this.setAssessmentLocally(response.data.id, response.data.ident);
              })
              .catch(() => {
                  _this.openErrorModal('Beim Erstellen Ihrer Sitzung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.');
              });
      },
      openErrorModal: function(msg) {
          this.errorModal.content = msg;
          this.errorModal.active = true;
      },
    },
    computed: {
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    created: function() {
        this.getSurvey();
        if(this.$route.params.savedAssessment) this.savedAssessment = this.$route.params.savedAssessment;
        this.startAssessment();
        if(this.$router.currentRoute.path !== '/analysis' && this.$router.currentRoute.path !== '/results') this.$router.push('/analysis');
    },
}
</script>

<style scoped>
.app-wrapper {
  position: relative;
  
  width: 100%;
  min-width: 1024px;
}
</style>
