<template>
    <div class="page-wrapper">
        <rg-header></rg-header>
        <rg-house></rg-house>
        <rg-survey></rg-survey>
        <rg-footer></rg-footer>
    </div>
</template>

<script>
import RgFooter from '../../gui/app/rgFooter.vue';
import RgHeader from '../../gui/app/rgHeader.vue';
import RgHouse from '../../gui/app/rgHouse.vue';
import RgSurvey from '../../gui/app/rgSurvey.vue';
export default {
    name: 'Analysis',
    data: function() {
        return {};
    },
    created: function() {
        // Scroll to top of page
        window.scrollTo(0, 0);
    },
    components: { RgHeader, RgHouse, RgSurvey, RgFooter },
}
</script>

<style>

</style>