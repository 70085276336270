<template>
    <div class="container">
        <div class="row">
            <div class="col-12 text-block-wrapper">
                <div class="space-50"></div>
                <h2><slot name="headline"></slot></h2>
                <p><slot name="content"></slot></p>
                <div class="space-50"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {};
    },
}
</script>

<style scoped>
.text-block-wrapper {
    text-align: center;
}
</style>