<template>
    <div class="bars-wrapper pdf-wrapper">
        <h2 class="pdf-headline">(1) Reifegrad-Übersicht über das gesamte Unternehmen</h2>
        <p>
            Im Balkendiagramm sehen Sie einen Überblick über Ihren Gesamtreifegrad heute, Ihren zukünftig avisierten Reifegrad und dem Handlungsbedarf als Differenz.
            <br><br>
            Skala von 0-3 (0 = niedrig, 3 = hoch)
        </p>
        <div class="space-50"></div>
        <div class="bars-container">
            <div class="bars-row">
                <div class="bars-col score-bar-label"><b>Reifegrad heute (arithmetisch gemittelt):</b></div>
                <div class="bars-col score-bar-wrapper">
                    <rg-score-bar
                        class="score-bar"
                        v-bind:color="'gray'"
                        v-bind:score="Math.round(overallValueToday*3*100)/100"
                        v-bind:maxWidthInPx="300"
                        v-bind:heightInEm="4"
                    ></rg-score-bar>
                </div>
            </div>
            <div class="bars-row">
                <div class="bars-col score-bar-label"><b>Reifegrad in 3 Jahren (arithmetisch gemittelt):</b></div>
                <div class="bars-col score-bar-wrapper">
                    <rg-score-bar
                        class="score-bar"
                        v-bind:color="'#425CA6'"
                        v-bind:score="Math.round(overallValueFuture*3*100)/100"
                        v-bind:maxWidthInPx="300"
                        v-bind:heightInEm="4"
                    ></rg-score-bar>
                </div>
            </div>
            <div class="bars-row">
                <div class="bars-col score-bar-label"><b>Handlungsbedarf (arithmetisch gemittelt):</b></div>
                <div class="bars-col score-bar-wrapper">
                    <rg-score-bar
                        class="score-bar"
                        v-bind:color="'#FF5252'"
                        v-bind:score="Math.round((overallValueFuture - overallValueToday)*3*1000)/1000"
                        v-bind:maxWidthInPx="300"
                        v-bind:heightInEm="4"
                    ></rg-score-bar>
                </div>
            </div>
            <div class="bars-row">
                <div class="bars-col score-bar-label"><b>Handlungsbedarf (gewichtet mit Relevanz)</b></div>
                <div class="bars-col score-bar-wrapper">
                    <rg-score-bar
                        class="score-bar"
                        v-bind:color="'#FF5252'"
                        v-bind:score="Math.round(overallValueNecessaryAction*3*100)/100"
                        v-bind:maxWidthInPx="300"
                        v-bind:heightInEm="4"
                    ></rg-score-bar>
                </div>
            </div>
            <div class="space-60"></div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../../config/assessmentStore';
import { useSurveyStore } from '../../../../config/surveyStore';
import rgScoreBar from '../rgScoreBar.vue';

export default {
    name: 'rgPdfBarsOverallResults',
    data: function() {
        return {};
    },
    computed: {
        overallValueToday: function() {
            let sum = 0
            for (let i = 1; i < 6; i++) {
                sum = sum + this.assessmentStore.getScoreByQuestionCategory('valueToday', 'category-'+i)                                
            }
            return sum / 5
        },
        overallValueFuture: function() {
            let sum = 0
            for (let i = 1; i < 6; i++) {
                sum = sum + this.assessmentStore.getScoreByQuestionCategory('valueFuture', 'category-'+i)                                
            }
            return sum / 5
        },
        overallValueNecessaryAction: function() {
            let sum = 0
            for (let i = 1; i < 6; i++) {
                sum = sum + this.assessmentStore.getScoreByQuestionCategory('necessaryAction', 'category-'+i)                                
            }
            return sum / 5
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    components: { rgScoreBar },
}
</script>

<style scoped>
.bars-container {

    justify-content: center;
}
.bars-row {
display: flex;
justify-content: left;

padding-top: .5em;
padding-bottom: .5em;
}
.bars-col {
display: flex;
justify-content: center;
align-items: center;
}
.score-bar-label {
max-width: 20em;

justify-content: right;
}
.score-bar-wrapper {
display: flex;
justify-content: left;

margin-left: 1em;

width: 400px;
}
</style>