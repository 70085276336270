import { defineStore } from 'pinia';
import _ from 'lodash';
import { useSurveyStore } from './surveyStore';

export const useAssessmentStore = defineStore('assessment', {
    state: () => {
        return {
            assessment: {
                id: null,
                ident: null,
                startDate: null,
                answers: [],
            },
        };
    },
    getters: {
        isQuestionAnswered: function() {
            return (answerId) => {
                const answer = this.getAnswerById(answerId);
                if(answer) {
                    return (answer.pertinance !== null && answer.valueToday !== null && answer.valueFuture !== null) || answer.pertinance == 0;
                } else {
                    return false;
                }
            }
        },
        isQuestionPostponed: function() {
            return (questionId) => {
                const answer = this.getAnswerByQuestion(questionId);
                if(answer) {
                    return answer.postponed;
                } else {
                    return false;
                }
            }
        },
        isQuestionGroupPostponed: function() {
            return (questionGroupId) => {
                const _this = this;
                const surveyStore = useSurveyStore();
                return surveyStore._flattenQuestionsAndSubquestions(surveyStore.getQuestionsByQuestiongroup(questionGroupId)).some((question) => {
                    return _this.isQuestionPostponed(question.id);
                });
            }
        },
        isQuestionCategoryPostponed: function() {
            return (questionCategoryId) => {
                const _this = this;
                const surveyStore = useSurveyStore();
                let isPostponed = false;
                surveyStore.getQuestionCategory(questionCategoryId).questiongroups.forEach((questionGroup) => {
                    if(_this.isQuestionGroupPostponed(questionGroup.id)) isPostponed = true; 
                });
                return isPostponed;
            };
        },
        isQuestionGroupFinished: function() {
            return (questionGroupId) => {
                const surveyStore = useSurveyStore();
                return surveyStore.getQuestionsCountByQuestiongroup(questionGroupId) === this.getAnswersCountByQuestiongroup(questionGroupId);
            }
        },
        getAnswerByQuestion: function() {
            return (questionId) => {
                return _.find(this.assessment.answers, (o) => {
                    if(o.question) return o.question.id === questionId;
                    if(o.subquestion) return o.subquestion.id === questionId;
                    return null;
                });
            }
        },
        getAnswerById: function() {
            return (answerId) => {
                return this.assessment.answers.find((element) => { return element.id === answerId });
            };
        },
        getAnswersWithScore: function() {
            const _this = this;
            return _.orderBy(_.map(_this.assessment.answers, (o) => {
                let score = _this.getScoreByAnswer(o);
                o.score = score;
                return o;
            }), ['score'], ['desc']);
        },
        getAnswersCountByQuestiongroup: function() {
            return(questionGroupId) => { // TODO: extract functionality getAnswerCount to own function
                const _this = this;
                const surveyStore = useSurveyStore();
                return surveyStore._flattenQuestionsAndSubquestions(surveyStore.getQuestionsByQuestiongroup(questionGroupId)).filter((question) => {
                    if(_this.getAnswerByQuestion(question.id)) {
                        return _this.isQuestionAnswered(_this.getAnswerByQuestion(question.id)['id']);
                    } else {
                        return false;
                    }
                }).length;
            }
        },
        getAnswersCountByQuestioncategory: function() {
            return(questionCategoryId) => { // TODO: extract functionality getAnswerCount to own function
                const _this = this;
                const surveyStore = useSurveyStore();
                return surveyStore._flattenQuestionsAndSubquestions(surveyStore.getQuestionsByQuestioncategory(questionCategoryId)).filter((question) => {
                    if(_this.getAnswerByQuestion(question.id)) {
                        return _this.isQuestionAnswered(_this.getAnswerByQuestion(question.id)['id']);
                    } else {
                        return false;
                    }
                }).length;
            }
        },
        getScoreByQuestionCategory: function() {
            return(computationPeriod, questionCategoryIdent) => {

                // Validate input params
                if(computationPeriod !== 'valueToday' && computationPeriod !== 'valueFuture' && computationPeriod !== 'necessaryAction') return false;

                const _this = this;
                const surveyStore = useSurveyStore();

                // Identify all appropriate questions
                let answers = [];
                surveyStore._flattenQuestionsAndSubquestions(surveyStore.getQuestionsByQuestioncategoryIdent(questionCategoryIdent)).forEach((question) => {
                    answers.push(_this.getAnswerByQuestion(question.id));
                });

                if(answers.length === 0) return 0;

                if(computationPeriod == 'necessaryAction') {
                    return _.sumBy(answers, (o) => { return _this.getScoreByAnswer(o) }) / (answers.length * 3);
                } else {
                    return _.sumBy(answers, (o) => { if(o) return o[computationPeriod] }) / (answers.length * 3);
                }
            };
        },
        getScoreByAnswer: function() {
            return(answer) => {
                if(answer) return (answer.valueFuture - answer.valueToday) * (answer.pertinance / 3);
            };
        },
        isQuestionCategoryFinished: function() {
            return (questionCategoryId) => {
                const surveyStore = useSurveyStore();
                return surveyStore.getQuestionsCountByQuestioncategory(questionCategoryId) === this.getAnswersCountByQuestioncategory(questionCategoryId);
            }
        },
        isAssessmentStarted: function() {
            return this.assessment.id !== null;
        },
        isAssessmentFinished: function() {
            const _this = this;
            const surveyStore = useSurveyStore();
            const questionCategories = surveyStore.getQuestionCategories;
            let isFinished = true;
            questionCategories.forEach((questionCategory) => {
                if(!_this.isQuestionCategoryFinished(questionCategory.id)) isFinished = false;
            });
            return isFinished;         
        },
        isAssessmentPostponed: function() {
            const _this = this;
            const surveyStore = useSurveyStore();
            const questionCategories = surveyStore.getQuestionCategories;
            let isPostponed = false;
            questionCategories.forEach((questionCategory) => {
                if(_this.isQuestionCategoryPostponed(questionCategory.id)) isPostponed = true;
            });
            return isPostponed;
        },
    },
    actions: {
        setAssessment: function(assessment) {
            this.assessment.id = assessment.id;
            this.assessment.ident = assessment.ident;
            this.assessment.startDate = assessment.startDate;
            if(assessment.answers) this.assessment.answers = assessment.answers;
        },
        createAnswer: function(answer) {
            this.assessment.answers.push(answer);
        },
        updateAnswer: function(answer) {
            const storeAssessment = this.assessment.answers[_.findIndex(this.assessment.answers, ['id', answer.id])];
            storeAssessment.pertinance = answer.pertinance;
            storeAssessment.valueToday = answer.valueToday;
            storeAssessment.valueFuture = answer.valueFuture;
            storeAssessment.postponed = answer.postponed;
        },
    }
});