<template>
    <div
        class="static-save-button"
        v-on:click="emitClick"
    >
        <md-icon class="static-save-button-icon">save</md-icon>
    </div>
</template>

<script>
export default {
    data: function() {
        return {};
    },
    methods: {
        emitClick: function() {
            this.$emit('click');
        },
    }
}
</script>

<style scoped>
.static-save-button {
    position: fixed;

    right: 1em;
    bottom: 1em;

    z-index: 30;

    width: 4em;
    height: 4em;

    cursor: pointer;
}
.static-save-button::after {
    content: '';
    position: absolute;
    z-index: -10;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: var(--color-customerinterface);

    -moz-filter: opacity(0.75);
    -webkit-filter: opacity(0.75);
    filter: opacity(0.75);
}
.static-save-button-icon {
    margin-top: .45em;

    font-size: 50px !important;

    color: var(--color-light) !important;
}
</style>