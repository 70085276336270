<template>
    <div>  
        <vue-html2pdf
            v-bind:show-layout="false"
            v-bind:float-layout="true"
            v-bind:enable-download="true"
            v-bind:preview-modal="false"
            v-bind:paginate-elements-by-height="1000"
            v-bind:filename="filename"
            v-bind:pdf-quality="2"
            v-bind:manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <rg-pdf-introduction></rg-pdf-introduction>
                <section class="pdf-item">
                    <rg-pdf-bars-overall-results></rg-pdf-bars-overall-results>
                </section>
                <section class="pdf-item">
                    <rg-pdf-bars-category-results></rg-pdf-bars-category-results>
                </section>
                <div class="html2pdf__page-break"/>
                <rg-top-ten-results-pdf-introduction></rg-top-ten-results-pdf-introduction>
                <rg-pdf-top-ten-results-tabular-entry
                    v-for="(answer, index) in topTenSlicedAnswersWithScore"
                    v-bind:key="index"
                    v-bind:index="index"
                    v-bind:answer="answer"
                ></rg-pdf-top-ten-results-tabular-entry>
                <div class="html2pdf__page-break"/>
                <rg-pdf-question-detail-introduction></rg-pdf-question-detail-introduction>
                <rg-pdf-question
                     v-for="(question, index) in activeQuestions"
                     v-bind:key="'parent-'+index"
                     v-bind:question="question"
                ></rg-pdf-question>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../../config/assessmentStore';
import VueHtml2pdf from 'vue-html2pdf';
import RgPdfIntroduction from './rgPdfIntroduction.vue';
import RgPdfBarsOverallResults from './rgPdfBarsOverallResults.vue';
import RgPdfBarsCategoryResults from './rgPdfBarsCategoryResults.vue';
import RgTopTenResultsPdfIntroduction from './rgTopTenResultsPdfIntroduction.vue';
import RgPdfTopTenResultsTabularEntry from './rgPdfTopTenResultsTabularEntry.vue';
import RgPdfQuestion from './rgPdfQuestion.vue';
import { useSurveyStore } from '../../../../config/surveyStore';
import RgPdfQuestionDetailIntroduction from './rgPdfQuestionDetailIntroduction.vue';

export default {
    name: 'rgPdfResults',
    data: function() {
        return {

        };
    },
    computed: {
        filename: function() {
            return "Reifegradanalyse-" + this.assessmentStore.assessment.ident + "-" + this.currentDate;
        },
        currentDate: function() {
            return new Date().toLocaleString('de-DE').substring(0,10);
        },
        topTenSlicedAnswersWithScore: function() {
            return this.assessmentStore.getAnswersWithScore.slice(0, 10);
        },
        activeQuestions: function() {
            return this.surveyStore._flattenQuestionsAndSubquestionsForPdf(this.surveyStore.getActiveQuestions);
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    methods: {
        generatePdf: function() {
            this.$refs.html2Pdf.generatePdf();
        },
    },
    components: { VueHtml2pdf, RgPdfIntroduction, RgPdfBarsOverallResults, RgPdfBarsCategoryResults, RgTopTenResultsPdfIntroduction, RgPdfTopTenResultsTabularEntry, RgPdfQuestion, RgPdfQuestionDetailIntroduction } // rgSurveyResultsPdf, 
}
</script>

<style>
h2.pdf-headline {
    margin-top: 0px;
    font-size: 24px;
}
.pdf-wrapper {
    padding: 3em;
    padding-bottom: 0px;
}
.top-ten-results {
    padding: 0px !important;
}
.idt-logo {
    width: 400px;

    margin: -.6em;
}
</style>