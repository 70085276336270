<template>
    <div class="house-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="space-50"></div>
                    <h2>In welches Handlungsfeld der Digitalisierung möchten Sie tiefer einsteigen?</h2>
                    <p>Wählen Sie Ihr Digitalisierungsfeld aus und steigen Sie ein in die Fragen. Mit der Beantwortung von 36 Fragen mit zahlreichen Unterbereichen zur Digitalisierung in fünf unterschiedlichen Handlungsfeldern können Sie Ihren digitalen Reifegrad sehr detailliert ermitteln. Durch Zwischenspeicherung erhalten Sie die Möglichkeit, jederzeit zu Ihren Antworten zurückzukehren. Um eine Auswertung zu erhalten, müssen Sie jedoch die gesamte Umfrage abschließen.</p>
                    <div class="space-50"></div>
                    <div class="house-img-wrapper">
                        <embed
                            class="house-img"
                            type="image/svg+xml"
                            v-bind:src="house"
                        />
                    </div>
                    <div class="house-click-matrix">
                        <div class="triangle" id="a" v-on:click="scrollTo('#category-1')"></div>
                        <div class="square" id="b" v-on:click="scrollTo('#category-2')"></div>
                        <div class="square" id="c" v-on:click="scrollTo('#category-3')"></div>
                        <div class="square" id="d" v-on:click="scrollTo('#category-4')"></div>
                        <div class="square" id="e" v-on:click="scrollTo('#category-5')"></div>
                    </div>
                    <div class="space-100"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'rgHouse',
    data: function() {
        return {

        };
    },
    computed: {
        house: function() {
            return require('../../../assets/img/house.svg');
        },
    },
    methods: {
        scrollTo: function(targetAnchor) {
            this.$scrollTo(targetAnchor);
        },
    }
}
</script>

<style scoped>

.house-wrapper {
    text-align: center;

    background-color: var(--color-gray);
}
.house-click-matrix {
    position: absolute;
}
.house-img-wrapper {
    width: 850px !important;
}
.triangle {
    position: absolute;
    width: 520px;

    height: 110px;
    bottom: 215px;

    cursor: pointer;
}
.square {
    position: absolute;
    width: 115px;
    height: 135px;

    bottom: 45px;
    cursor: pointer;
}
#a {
    left: 160px;
}
#b {
    left: 160px;
}
#c {
    left: 295px;
}
#d {
    left: 430px;
}
#e {
    left: 565px;
}

</style>