<template>
    <div class="action-block-container-wrapper">
        <div class="container">
            <div class="row action-block-container">
                <div class="space-50"></div>
                <rg-action-block
                    v-for="(actionBlock, index) in actionBlocks"
                    v-bind:key="index"
                    v-bind:icon="actionBlock.icon"
                    v-bind:headline="actionBlock.headline"
                    v-bind:text="actionBlock.text"
                    v-bind:buttonText="actionBlock.buttonText"
                    v-bind:buttonTarget="actionBlock.buttonTarget"  
                    v-on:evaluateAssessment="evaluateAssessment"
                    v-on:saveAssessment="saveAssessment"       
                    v-on:generatePdf="generatePdf"  
                ></rg-action-block>
                <div class="space-100"></div>
                <rg-static-save-button v-on:click="saveAssessment"></rg-static-save-button>
            </div>
        </div>
        <md-dialog
            v-bind:md-active.sync="modal.active"
        >
            <md-dialog-title>{{ modal.title }}</md-dialog-title>
            <md-dialog-content>
                <p>{{ modal.content }}</p>
                <div
                    class="save-modal-content"
                    v-if="modal.action === 'save'"
                >
                    <span class="save-modal-link">{{ saveLink }}</span>
                    <div class="space-20"></div>
                    <p>Gerne schicken wir Ihnen Ihr Ergebnis auch per E-Mail. Ihre Mailadresse verwenden wir dabei ausschließlich für den Versand Ihrer Umfrage:</p>
                    <form v-on:submit.prevent="submitEmailForm">
                        <div class="form-group" v-bind:class="{ 'form-group--error': $v.email.$error }">
                            <input
                                class="form__input mail-input"
                                v-model="email"
                                placeholder="Ihre E-Mail-Adresse"
                                v-on:blur="$v.email.$touch()"
                            >
                            <rg-button
                                type="submit"
                                v-bind:disabled="loading"
                            >Senden</rg-button>
                            <div class="error" v-if="$v.email.$error">Bitte geben Sie eine gültige Mailadresse ein.</div>
                        </div>
                    </form>
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" v-on:click="modal.active = false">{{ modal.confirmText }}</md-button>
            </md-dialog-actions>
        </md-dialog>
        <rg-pdf-results ref="rgPdfResults"></rg-pdf-results>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import { required, email } from 'vuelidate/lib/validators'
import rgActionBlock from './rgActionBlock.vue';
import RgButton from './rgButton.vue';
import RgPdfResults from './pdf/rgPdfResults.vue';
import RgStaticSaveButton from './rgStaticSaveButton.vue';

export default {
    name: 'rgActionBlockContainerResults',
    data: function() {
        return {
            actionBlocks: [
                {
                    icon: require('../../../assets/img/icons/result.png'),
                    headline: 'Zurück zur Befragung',
                    text: 'Gehen Sie zurück zur Befragung, um bei Bedarf auch nachträglich noch Änderungen an Ihrer Umfrage vorzunehmen. Wenn Sie Ihre Umfrage speichern können Sie auch zu einem späteren Zeitpunkt jederzeit wieder zurück zur Befragung gehen.',
                    buttonText: 'Zur Befragung',
                    buttonTarget: function() {
                        this.$router.push('/analysis');
                    },
                },
                {
                    icon: require('../../../assets/img/icons/download.png'),
                    headline: 'PDF zum Download',
                    text: 'Laden Sie sich Ihr Umfrageergebnis als PDF-Dokument herunter, um Ihre Ergebnisse auch offline weiterzuverwenden und sie bei Bedarf mit anderen Personen zu teilen. Der Downloaded startet nach wenigen Sekunden automatisch.',
                    buttonText: 'PDF-Download',
                    buttonTarget: function() {
                        this.$emit('generatePdf');
                    },
                },
                {
                    icon: require('../../../assets/img/icons/save.png'),
                    headline: 'Umfrage speichern',
                    text: 'Um zu einem späteren Zeitpunkt erneut auf Ihre Umfrage oder Ihre Auswertung zugreifen zu können, erhalten Sie von uns einen Code - gerne auch per Mail. Ihre E-Mail-Adresse wird von uns ausschließlich für den Mailversand verwendet.',
                    buttonText: 'Speichern',
                    buttonTarget: function() {
                        this.$emit('saveAssessment');
                    },
                },
            ],
            modal: {
                active: false,
                title: null,
                content: null,
                confirmText: null,
                action: null,
            },
            email: '',
            loading: false,
        };
    },
    validations: {
        email: {
            required,
            email,
        }        
    },
    methods: {
        evaluateAssessment: function() {
            if(this.assessmentStore.isAssessmentFinished && !this.assessmentStore.isAssessmentPostponed) {
                this.$router.push({ path: '/results' });
            } else {
                this._openErrorModal(
                    "Umfrage auswerten",
                    "Bitte beantworten Sie zuerst alle Fragen der Umfrage und schalten Sie alle Vormerkungen (\"Frage später beantworten\") aus bevor Sie zur Auswertung gehen.",
                    "Okay",
                    "evaluate"
                );
            }

        },
        saveAssessment: function() {
            this._openErrorModal(
                "Umfrage speichern",
                "Angaben zu Ihrem digitalen Reifegrad - über diesen Link können Sie Ihr Ergebnis zu einem späteren Zeitpunkt erneut abrufen:",
                "Schließen",
                "save"
            );
        },
        generatePdf: function() {
            this.$refs.rgPdfResults.generatePdf();
        },
        submitEmailForm: function() {
            const _this = this;
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.$http.post('/assessments/sendmail', {
                    email: _this.email,
                    ident: _this.assessmentStore.assessment.ident,
                });
            }
        },
        _openErrorModal: function(title, content, confirmText, action) {
            this.modal.title = title;
            this.modal.content = content;
            this.modal.confirmText = confirmText;
            this.modal.action = action;
            this.modal.active = true;
        },
    },
    computed: {
        saveLink: function() {
            return (process.env.VUE_APP_API_URL) ? (process.env.VUE_APP_API_URL) : ('https://reifegradanalyse.hnu.de/api') + '/' + this.assessmentStore.assessment.ident;
        },
        ...mapStores(useAssessmentStore),

    },
    components: { rgActionBlock, RgButton, RgPdfResults, RgStaticSaveButton },
}
</script>

<style scoped>
.action-block-container-wrapper {
    position: relative;

    background-color: var(--color-gray-shade);
}
.action-block-container {
    text-align: center;
}
.save-modal-link {
    font-weight: bold;
    font-size: 1.25em;
}
.save-modal-content {
    text-align: center;
}
.mail-input {
    padding: .4em 1.2em .4em 1.2em;
    margin-right: .5em;
    margin-bottom: .5em;

    border: 1px solid inherit;

    background: none;
    cursor: pointer;

    font-size: var(--font-size-button);
}
</style>