<template>
    <div class="question-wrapper pdf-item">
        <div
            class="parent-question-wrapper"
        >
            <div class="question-name question-spacer">
                <p class="question-text" v-bind:class="{ 'subquestion-text': isSubquestion }">
                    <span v-if="!isSubquestion"><b>Frage {{ surveyStore.getQuestionIndexById(question.id) + 1 }}:</b><br></span> 
                    {{ question.name }}
                </p>
            </div>
            <div class="question-cols rating-header">
                <rg-score-bar
                    v-if="(answer !== undefined)"
                    v-bind:score="answer.pertinance"
                    v-bind:color="'#EDEDED'"
                    v-bind:maxWidthInPx="50"
                    v-bind:heightInEm="2"
                ></rg-score-bar>
            </div>
            <div class="question-cols rating-header">
                <rg-score-bar
                    v-if="(answer !== undefined)"
                    v-bind:score="answer.valueToday"
                    v-bind:color="'gray'"
                    v-bind:maxWidthInPx="50"
                    v-bind:heightInEm="2"
                ></rg-score-bar>
            </div>
            <div class="question-cols rating-header">
                <rg-score-bar
                    v-if="(answer !== undefined)"
                    v-bind:score="answer.valueFuture"
                    v-bind:color="'#425CA6'"
                    v-bind:maxWidthInPx="50"
                    v-bind:heightInEm="2"
                ></rg-score-bar>
            </div>
            <div class="question-cols rating-header">
                <rg-score-bar
                    v-if="(answer !== undefined)"
                    v-bind:score="answer.score"
                    v-bind:color="'#FF5252'"
                    v-bind:maxWidthInPx="50"
                    v-bind:heightInEm="2"
                ></rg-score-bar>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapStores } from 'pinia';
import { useAssessmentStore } from '../../../../config/assessmentStore';
import { useSurveyStore } from '../../../../config/surveyStore';
import RgScoreBar from '../rgScoreBar.vue';

export default {
    name: 'rgPdfQuestion',
    data: function() {
        return {};
    },
    computed: {
        answer: function() {
            return this.assessmentStore.getAnswerByQuestion(this.question.id);
        },
        isSubquestion: function() {
            return this.surveyStore._isSubquestion(this.question.id);
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    methods: {
        ...mapActions(useAssessmentStore, ['isQuestionAnswered']),
    },
    created: function() {
        console.log(this.question);
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    components: { RgScoreBar },
}
</script>

<style scoped>
.question-wrapper {
    margin-left: 3em;
    margin-right: 3em;
}
.parent-question-wrapper {
    display: flex;
    flex-direction: row;

}
.parent-question-wrapper .question-cols {
    display: flex;
    flex: 1;

    align-items: center;

    border-bottom: 1px solid var(--color-dark);
}
.question-spacer {
    width: 50%;

    border-bottom: 1px solid var(--color-dark);
}
.question-text {
    font-size: .85em;  
    
    padding-right: .5em;

    margin-bottom: .75em;
}
.subquestion-text {
    padding-left: 2em;
}
.child-questions-wrapper .question-text {
    font-size: .95em;  
    
    padding-left: 1.5em;
    padding-right: .5em;
}
.rating-header {
    border-left: 2px solid var(--color-dark);
}
.postpone {
    width: 12%;
    
    font-size: .7em;
    line-height: 1.4em;

    border-left: 2px solid var(--color-dark);
    border-right: 2px solid var(--color-dark);
}
.gray {
    background: var(--color-gray);
}
.gray-shade {
    background: var(--color-gray-shade);
}
.question-postpone-switch {
    margin-top: 1.5em;
}
.md-switch.md-theme-default.md-checked .md-switch-container {
    background-color: none !important;
}

</style>