<template>
    <router-link
        class="action-tile-wrapper"
        v-bind:to="link"
    >
        <div class="action-tile-icon-wrapper">
            <i
                v-bind:class="icon"
                class="action-tile-icon"
            ></i>
        </div>
        <div class="action-tile-text-wrapper">
            <h4>{{ headline }}</h4>
            <p>{{ description }}</p>
            <div class="clearfix"></div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'ActionTile',
    data: function() {
        return {};
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        headline: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        }
    },
}
</script>

<style scoped>
.action-tile-wrapper {
    display: flex;
    padding: .5em 1em 0em 0em;

    background-color: var(--color-gray);

    cursor: pointer;

    text-decoration: none;
}
.action-tile-wrapper:HOVER {
    background-color: var(--color-primary);

    -webkit-transition: background 0.3s 0s ease-in-out;
    -moz-transition: background 0.3s 0s ease-in-out;
    -o-transition: background 0.3s 0s ease-in-out;
    transition: background 0.3s 0s ease-in-out;
}
.action-tile-wrapper:HOVER h4 {
    color: var(--color-light);

    -webkit-transition: color 0.3s 0s ease-in-out;
    -moz-transition: color 0.3s 0s ease-in-out;
    -o-transition: color 0.3s 0s ease-in-out;
    transition: color 0.3s 0s ease-in-out;
}
.action-tile-wrapper:HOVER p {
    color: var(--color-light);

    -webkit-transition: color 0.3s 0s ease-in-out;
    -moz-transition: color 0.3s 0s ease-in-out;
    -o-transition: color 0.3s 0s ease-in-out;
    transition: color 0.3s 0s ease-in-out;
}
.action-tile-wrapper:HOVER .action-tile-icon {
    color: var(--color-light);

    -webkit-transition: color 0.3s 0s ease-in-out;
    -moz-transition: color 0.3s 0s ease-in-out;
    -o-transition: color 0.3s 0s ease-in-out;
    transition: color 0.3s 0s ease-in-out;
}
.action-tile-icon-wrapper {
    display: flex;

    padding: 1.3em 1em 0em 1em;

    align-items: flex-start;
    justify-content: center;
}
.action-tile-icon {
    font-size: 3em;
    color: var(--color-text)
}

</style>