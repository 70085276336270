<template>
    <div class="question-category-header-wrapper">
        <rg-icon-tile
            v-bind:color="displaycolor"
            v-bind:iconSrc="icon"
            class="rg-icon-tile"
        ></rg-icon-tile>        
        <h3
            class="rg-question-category-header"
            v-bind:id="questionCategory.ident"
        >{{ name }}</h3>
        <rg-question-category-progress-text
            v-bind:color="displaycolor"
            v-bind:questionCategory="questionCategory"
        ></rg-question-category-progress-text>
        <!--
        <rg-question-category-progress-bar
            v-bind:color="displaycolor"
            v-bind:progress="progress"
            class="rg-question-category-progress-bar"
        ></rg-question-category-progress-bar>
        -->
        <div class="clearfix"></div>
    </div>
</template>

<script>
import rgIconTile from './rgIconTile.vue'
import RgQuestionCategoryProgressText from './rgQuestionCategoryProgressText.vue';

export default {
    name: 'rgQuestionCategoryHeader',
    data: function() {
        return {};
    },
    methods: {},
    props: {
        displaycolor: {
            required: true,
            type: String,
        },
        icon: {
            required: true,
            type: String,
        },
        name: {
            required: true,
            type: String,
        },
        questionCategory: {
            required: true,
            type: Object,
        }
    },
    components: { rgIconTile, RgQuestionCategoryProgressText },
}
</script>

<style scoped>
.question-category-header-wrapper {
    position: relative;
}
.rg-icon-tile {
    display: block;
    position: relative;
    float: left;

    margin-right: 4em;
}
.rg-question-category-header {
    display: block; 
    position: relative;
    float: left;

    top: .8em;

    max-width: 70%;

    font-size: 1.75em;
}
.rg-question-category-progress-bar {
    position: absolute;

    bottom: 0px;

    margin-left: 13em;

}
</style>