<template>
    <section class="pdf-item">
        <div class="top-ten-results-entry-wrapper">
            <div class="question-text-wrapper">
                <span class="question-group-name">{{ getQuestiongroupByAnswer.name }}, Frage {{ getQuestionIndex + 1 }}</span>
                <span class="question-text-container">{{ getQuestionByAnswer.name }}</span>
                <span class="subquestion-text-wrapper" v-if="answer.subquestion">{{ answer.subquestion.name }}</span>
            </div>
            <div class="score-wrapper">
                <rg-score-bar
                    class="score-bar"
                    v-bind:color="'#FF5252'"
                    v-bind:score="answer.score"
                    v-bind:maxWidthInPx="80"
                    v-bind:heightInEm="1"
                ></rg-score-bar>
            </div>
        </div>
        <div class="space-5"></div>
    </section>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../../config/assessmentStore';
import { useSurveyStore } from '../../../../config/surveyStore';
import RgScoreBar from '../rgScoreBar.vue';

export default {
    name: 'rgTopTenResultsEntry',
    data: function() {
        return {
        };
    },
    computed: {
        getQuestionByAnswer: function() {
            // Get question index
            let question = null;
            if(this.answer.question) {
                question = this.answer.question;          
            } else {
                question = this.surveyStore.getQuestionBySubquestion(this.answer.subquestion.id);
            }
            return question;
        },
        getQuestiongroupByAnswer: function() {
            return this.surveyStore.getQuestiongroupByQuestion(this.getQuestionByAnswer.id);
        },
        getQuestionIndex: function() {
            return this.surveyStore.getQuestionIndexById(this.getQuestionByAnswer.id);
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        }
    },
    components: { RgScoreBar }
}
</script>

<style scoped>
.top-ten-results-entry-wrapper {
    display: flex;
    flex-direction: row;

    margin-bottom: .5em;
    margin-left: 3em;
    margin-right: 3em;

    background-color: var(--color-gray);
}
.top-ten-results-entry-wrapper div {
    display: flex;

    align-items: center;

    padding-top: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
}
.question-index-wrapper {
    /*
    display: flex;
    width: 15%;

    justify-content: center;
    text-align: end;
    */
    font-weight: bold;
}
.question-text-wrapper {
    width: 80%;

    flex-direction: column;

    text-align: left;

    color: var(--color-dark);
    font-size: 10px;
    line-height: 10px;
    /*
    line-height: var(--font-line-height-text);
    */
}
.question-text-container {
    display: flex;
    align-self: flex-start;
    align-content: flex-start;
    text-align: left;
}
.subquestion-text-wrapper {
    display: flex;
    align-self: flex-start;
    align-content: flex-start;
    text-align: left;

    font-weight: bold;

    /*
    line-height: var(--font-line-height-text);
    */
}
.score-wrapper {
    width: 20%;

    justify-content: start;
    text-align: left;

    padding-left: 0em !important;

    border-left: 2px solid var(--color-light);
}
.question-group-name {
    font-style: italic;
    font-weight: lighter;
    align-self: flex-start;
    line-height: var(--font-line-height-text);
}

</style>