<template>
    <div
        class="answer-radio-button"
        v-bind:class="(buttonSelected) ? ('selected') : ('unselected')"
        v-bind:style="cssVarsButtonColor"
        v-on:click="buttonClicked"
    >
        <span class="material-icons checkmark" v-if="buttonSelected">done</span>
    </div>
</template>

<script>
export default {
    name: 'rgAnswerRadioButton',
    data: function() {
        return {};
    },
    computed: {
        cssVarsButtonColor: function() {
            return {
                '--rg-answer-radio-button-displaycolor': this.displaycolor,
            };
        },
        buttonSelected: function() {
            if(this.currentRadioValue === this.buttonValue) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        buttonClicked: function() {
            if(this.currentRadioValue !== this.buttonValue) {
                this.$emit('buttonClicked', this.buttonValue);
            }
        },
    },
    props: {
        buttonValue: {
            type: Number,
            required: true,
        },
        displaycolor: {
            type: String,
            required: true,
        },
        currentRadioValue: {
            type: Number,
            required: false,
        }
    }
}
</script>

<style scoped>
.answer-radio-button {
    position: relative;

    width: 1.25em;
    height: 1.25em;

    padding-top: 2px;

    -moz-border-radius: .625em; 
    -webkit-border-radius: .625em; 
    border-radius: .625em;

    cursor: pointer;
}
.selected {
    background-color: var(--rg-answer-radio-button-displaycolor);
}
.unselected {
    background-color: var(--color-light);
}
.checkmark {
    color: var(--color-light);
    font-size: 1.1em;
}

</style>