<template>
    <div>
        <div style="text-align: center;">
            <h1>Login</h1>
            <p>
                Melden Sie sich hier an unserem System an.
            </p>
        </div>
        <fieldset
            class="login-wrapper"
        >
            <legend
                style="margin-left: 8px;"
            >Ihre Zugangsdaten</legend>
            <label
                for="email"
                class="login-form-label"
            >E-Mail</label>
            <input
                type="text"
                id="email"
                class="login-form-element"
                v-model="email"
            >
            <label
                for="password"
                class="login-form-label"
            >Passwort</label>
            <input
                type="password"
                id="password"
                class="login-form-element"
                v-model="password"
            >
            <button
                class="login-form-element"
                v-on:click="login"
            >Anmelden</button>
        </fieldset>
    </div>
</template>

<script>
export default {
  name: 'Login',
  data: () => {
      return {
          email: '',
          password: '',
      };
  },
  methods: {
      login() {
        this.$auth.login({
            data: {
                email: this.email,
                password: this.password,
            },
            redirect: { path: '/admin' },
            staySignedIn: false,
            fetchUser: true,
        })
        .then((res) => {
            // Set user data
            this.$auth.user(res.data);
        })
        .catch((err) => {
            console.log("error")
            console.log(err)
            /*
            if(err.statusCode == '401') {
                alert("Die Zugangsdaten sind falsch.")
            }
            console.log(err)
            */
        })
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-wrapper {
    padding: 30px;
    margin: 3em auto 0 auto;
    max-width: 20em;
    text-align: left;
}
.login-form-element {
    display: block;
    position: relative;
    left: 0;
    right: 0;
    margin: 10px;
    padding: 10px;
}
.login-form-label {
    padding-left: 10px;
}
</style>
