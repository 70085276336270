export default {
    request: function (req, token) {
      // Set token in header as bearer token
      this.drivers.http.setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      });  
    },
    response: function (res) {
      // Retrieve token from server
      var token = res.data.accessToken;
      if (token) {
        token = token.split(/Bearer:?\s?/i);
        return token[token.length > 1 ? 1 : 0].trim();
      }
    }
  };
  