<template>
    <header class="rg-header">
        <div class="container">
            <div class="rg-header-content-wrapper">
                <div class="rg-header-content-logo-wrapper">
                    <img src="../../../assets/img/logo.png" />
                </div>
                <div class="rg-header-content-text-wrapper">
                    <h1>Reifegrad Online Tool</h1>
                    <p>
                        Unterstützung auf dem Weg zur Digitalen Transformation
                        durch einen ganzheitlichen Überblick auf Ihr Unternehmen.
                    </p>
                    <!-- <rg-button styletype="color-light" href="google.de">Zum Erklärvideo</rg-button> -->
                </div>
            </div>
        </div>
    </header>
</template>

<script>
// import RgButton from './rgButton.vue';

export default {
    name: 'rgHeader',
    data: function() {
        return {

        };
    },
    components: {
        // RgButton
    }
}
</script>

<style scoped>
.rg-header {
    position: relative;

    width: 100%;
    height: 40em;
}
.rg-header::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-image: url('../../../assets/img/header.jpg');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    -moz-filter: grayscale(1);
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}
.rg-header-content-wrapper {
    position: absolute;

    width: 22em;
    height: 100%;

    color: var(--color-light);
}
.rg-header-content-logo-wrapper {
    height: 12em;
    width: 100%;

    padding-top: 1em;

    display: flex;
    align-items: center;
}
.rg-header-content-text-wrapper {
    position: absolute;
    bottom: 0;

    z-index: 10;

    width: 100%;
    height: 26em;

    padding: 2em;
}
.rg-header-content-text-wrapper h1, p {
    color: var(--color-light);
}
.rg-header-content-text-wrapper::after {
    content: '';
    position: absolute;
    z-index: -10;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: var(--color-customerinterface);

    -moz-filter: opacity(0.75);
    -webkit-filter: opacity(0.75);
    filter: opacity(0.75);
}

</style>