// Set up Vue instance
import Vue                      from "vue"
import Router                   from "vue-router"

// Import components
import Admin                    from "../components/admin/Admin"
import Login                    from "../components/auth/Login"
import Home                     from "../components/home/Home"
// import NotAllowed            from "@/components/errors/NotAllowed"
// import NotFound              from "@/components/errors/NotFound"

import Analysis                 from "../components/home/analysis/Analysis"
import Results                  from "../components/home/results/Results"

import AdminDashboard           from "../components/admin/dashboard/AdminDashboard"
import AdminSurveys             from "../components/admin/surveys/AdminSurveys"
import AdminQuestions           from "../components/admin/questions/AdminQuestions"
import AdminUsers               from "../components/admin/users/AdminUsers"

// Use router
Vue.use(Router)

// TODO: fix issue that authenticated user can no longer see normal app pages, only dashboard pages

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/:savedAssessment?',
            name: 'Home',
            component: Home,
            // redirect: '/analysis',
            meta: {
                auth: false,
            },
            children: [
                {
                    path: '/analysis',
                    component: Analysis,
                    meta: {
                        auth: false,
                    },        
                },
                {
                    path: '/results',
                    component: Results,
                    meta: {
                        auth: false,
                    },
                }
            ],
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                auth: false,
            }
        },
        {
            path: '/admin',
            name: 'Admin',
            component: Admin,
            redirect: '/admin/dashboard',
            meta: {
                auth: true,
            },
            children: [
                {
                    path: 'dashboard',
                    component: AdminDashboard,
                },
                {
                    path: 'surveys',
                    component: AdminSurveys,
                },
                {
                    path: 'questions',
                    component: AdminQuestions,
                },
                {
                    path: 'users',
                    component: AdminUsers,
                }
            ]
        },
    ],
});