<template>
    <div class="main-wrapper">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'Main',
    components: {
    }
}
</script>

<style scoped>
.main-wrapper {
    position: fixed;

    min-width: 1000px;

    right: 0px;
    left: 8em;
    top: 0px;
    bottom: 0px;

    padding-bottom: 10em;

    overflow-y: scroll;
    overflow-x: hidden;
    
    background: var(--color-light);
}

</style>