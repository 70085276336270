<template>
    <section class="pdf-item">
    <div class="pdf-wrapper">
        <img src="../../../../assets/img/idt-logo.png" class="idt-logo">
        <div>
            <h1>Ergebnisse Reifegradanalyse</h1>
            <h4>Stand {{ currentDate }}</h4>
            <p>
                Auf den nachfolgenden Seiten erhalten Sie die Ergebnisse Ihrer Reifegradanalyse.
                Gerne unterstützen wir Sie bei der Erarbeitung Ihrer ganzheitlichen Digitalisierungs-Strategie.
                Bei Fragen und Anregungen wenden Sie sich an das Institut für Digitale Transformation der Hochschule Neu-Ulm.
                <br>

            </p>
            <div class="report-details">
                <!--
                <div class="report-details-row">
                    <div class="report-details-col">Export-Datum</div>
                    <div class="report-details-col report-details-col-value">{{ currentDate }}</div>
                </div>
                -->
                <div class="report-details-row">
                    <div class="report-details-col">Auswertungs-Code</div>
                    <div class="report-details-col report-details-col-value">{{ assessmentStore.assessment.ident }}</div>
                </div>
                <div class="report-details-row">
                    <div class="report-details-col">Link zur Erhebung</div>
                    <div class="report-details-col report-details-col-value">https://reifegradanalyse.hnu.de/{{ assessmentStore.assessment.ident }}</div>
                </div>
                <div class="report-details-row">
                    <div class="report-details-col">Kontakt</div>
                    <div class="report-details-col report-details-col-value">
                        Institut für Digitale Transformation (IDT)<br>
                        Hochschule Neu-Ulm<br>
                        Wileystraße 1<br>
                        89231 Neu-Ulm<br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../../config/assessmentStore';

export default {
    data: function() {
        return {}
    },
    computed: {
        currentDate: function() {
            return new Date().toLocaleString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' });
        },
        ...mapStores(useAssessmentStore),
    }
}
</script>

<style scoped>
.report-details-row {
    display: flex;
    flex-direction: row;
}
.report-details-col {
    width: 12em;
    padding: .5em 0 .5em 0;
}
.report-details-col-value {
    font-weight: bold;
    line-height: 1.2em;
}

</style>