<template>
    <div class="top-ten-results-entry-wrapper">
        <div class="index-wrapper">
            <rg-number-tile
                v-bind:color="'#425CA6'"
                v-bind:number="index + 1"
                v-bind:tileColor="'#FFFFFF'"
            ></rg-number-tile>
        </div>
        <div class="question-index-wrapper">Frage {{ getQuestionIndex + 1 }}</div>
        <div class="question-text-wrapper">
            {{ getQuestionByAnswer.name }}
            <span class="subquestion-text-wrapper" v-if="answer.subquestion"><md-icon class="question-group-button-icon">arrow_forward</md-icon> {{ answer.subquestion.name }}</span>
            <span class="question-group-name">{{ getQuestiongroupByAnswer.name }}</span>
        </div>
        <div class="score-wrapper">
            <rg-score-bar
                class="score-bar"
                v-bind:color="'#FF5252'"
                v-bind:score="answer.score"
                v-bind:maxWidthInPx="100"
                v-bind:heightInEm="2"
            ></rg-score-bar>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAssessmentStore } from '../../../config/assessmentStore';
import { useSurveyStore } from '../../../config/surveyStore';
import RgNumberTile from './rgNumberTile.vue';
import RgScoreBar from './rgScoreBar.vue';

export default {
    name: 'rgTopTenResultsEntry',
    data: function() {
        return {
        };
    },
    computed: {
        getQuestionByAnswer: function() {
            // Get question index
            let question = null;
            if(this.answer.question) {
                question = this.answer.question;          
            } else {
                question = this.surveyStore.getQuestionBySubquestion(this.answer.subquestion.id);
            }
            return question;
        },
        getQuestiongroupByAnswer: function() {
            return this.surveyStore.getQuestiongroupByQuestion(this.getQuestionByAnswer.id);
        },
        getQuestionIndex: function() {
            return this.surveyStore.getQuestionIndexById(this.getQuestionByAnswer.id);
        },
        ...mapStores(useAssessmentStore, useSurveyStore),
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        answer: {
            type: Object,
            required: true,
        }
    },
    components: { RgNumberTile, RgScoreBar }
}
</script>

<style scoped>
.top-ten-results-entry-wrapper {
    display: flex;
    flex-direction: row;

    margin-bottom: .5em;

    background-color: var(--color-gray);
}
.top-ten-results-entry-wrapper div {
    display: flex;

    align-items: center;

    padding-top: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
}
.index-wrapper {
    width: 10%;
}
.question-index-wrapper {
    display: flex;
    width: 15%;

    justify-content: center;
    text-align: end;
    font-weight: bold;
}
.question-text-wrapper {
    width: 55%;

    flex-direction: column;

    text-align: left;

    color: var(--color-dark);
    line-height: var(--font-line-height-text);
}
.subquestion-text-wrapper {
    align-self: flex-start;

    line-height: var(--font-line-height-text);
}
.score-wrapper {
    width: 20%;

    justify-content: start;
    text-align: left;

    padding-left: 0em !important;

    border-left: 2px solid var(--color-light);
}
.question-group-name {
    font-style: italic;
    font-weight: lighter;
    align-self: flex-start;
    line-height: var(--font-line-height-text);
}

</style>