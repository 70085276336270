<template>
    <aside class="main-navigation">
        <div class="main-navigation-logo-wrapper">
            <img
                alt="HNU Logo"
                class="main-navigation-logo"
                src="../../../assets/img/logo-weiß-bildmarke.svg"
            >
        </div>
        <ul class="main-navigation-list">
            <li
                v-for="(navEntry, index) in navigation"
                v-bind:key="index"
                class="main-navigation-list-entry"
            >
                <router-link
                    class="main-navigation-router-link"
                    v-bind:to="navEntry.link"
                >
                    <i
                        v-bind:class="navEntry.icon"
                        class="main-navigation-list-entry-icon"
                    ></i>
                    <div class="main-navigation-list-entry-text">
                        {{ navEntry.name }}
                    </div>
                </router-link>
            </li>
            <li class="main-navigation-list-entry">
                <!--
                <i
                    class="icofont-dashboard main-navigation-list-entry-icon"
                ></i>
                -->
                <div class="space-30"></div>
                <div
                    class="main-navigation-list-entry-text"
                    v-on:click="logout"
                >
                    Abmelden
                </div>
            </li>
        </ul>
    </aside>
</template>

<script>
export default {
    name: 'Navigation',
    data: () => {
        return {
            navigation: [
                {
                    name: 'Dashboard',
                    icon: 'icofont-dashboard',
                    link: '/admin/dashboard',
                    role: 'admin'
                },
                {
                    name: 'Umfragen',
                    icon: 'icofont-unique-idea',
                    link: '/admin/surveys',
                    role: 'admin'
                },
                {
                    name: 'Fragen',
                    icon: 'icofont-question-circle',
                    link: '/admin/questions',
                    role: 'admin'
                },
                {
                    name: 'Benutzer',
                    icon: 'icofont-male',
                    link: '/admin/users',
                    role: 'admin'
                },
                {
                    name: 'Einstellungen',
                    icon: 'icofont-settings',
                    link: '/admin/settings',
                    role: 'admin'
                }
            ],
        };
    },
    methods: {
        logout: function() {
            this.$auth.logout();
        },
    }
}
</script>

<style scoped>
.main-navigation {
    position: fixed;
    width: 8em;
    height: 100%;
    bottom: 0px !important;

    background: var(--color-primary);
}
.main-navigation-logo-wrapper {
    position: relative;
    margin: 1em 0 3em 0;
    width: 8em;
    padding: 1em;
}
.main-navigation-logo {
    display: block;
    position: relative;

    width: 6em;
}
.main-navigation-list {
    list-style: none;
    padding: 0;

    color: var(--color-light);
}
.main-navigation-router-link {
    display: block;
    position: relative;
    height: 100%;

    padding-top: 1.4em;
    padding-bottom: 1.4em;
    
    text-decoration: none !important;
    color: inherit !important;
}
.main-navigation-router-link:HOVER {
    background-color: var(--color-primary-shade-darker);
}
.main-navigation-list-entry {
    display: block;
    text-align: center;
}
.main-navigation-list-entry-icon {
    display: inline-block;
    margin-bottom: .5em;

    text-align: center;
    font-size: 1.7em;
}
.main-navigation-list-entry-text {
    font-size: var(--font-size-default);
}
.main-navigation-list-entry:not(:last-child) {
    border-bottom: none;
}

/* Router styles */
.router-link-active {
    background: var(--color-primary-shade-darker);

    border-left: 5px solid var(--color-light);
    border-right: 5px solid var(--color-primary-shade-darker);
}
.router-link-active:HOVER {
    border-left: 5px solid var(--color-light);
    border-right: 5px solid var(--color-primary-shade-darker);
}
</style>