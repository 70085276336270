<template>
    <div class="question-group-table-head-wrapper">
        <div class="question-group-table-head-cols question-spacer">
        </div>
        <div class="question-group-table-head-cols rating-header">
            <div>
                Relevanz
                <md-icon class="information-icon">
                    info_outline
                    <md-tooltip md-direction="top" class="table-head-tooltip">
                        Die Relevanz für Ihr Unternehmen
                    </md-tooltip>
                </md-icon>
            </div>
        </div>
        <div class="question-group-table-head-cols rating-header" v-if="visibility.isStateVisible">
            <div>
                Ist-Digitali-<br>sierungs-<br>grad
                <md-icon class="information-icon">
                    info_outline
                    <md-tooltip md-direction="top" class="table-head-tooltip">
                        Beschreibt die aktuelle Sicht auf Ihr Unternehmen
                    </md-tooltip>
                </md-icon>
            </div>
        </div>
        <div class="question-group-table-head-cols rating-header" v-if="visibility.futureStateVisible">
            <div>
                Digitalisie-rungsgrad in 3 Jahren
                <md-icon class="information-icon">
                    info_outline
                    <md-tooltip md-direction="top" class="table-head-tooltip">
                        Beschreibt die gewünschte zukünftige Sicht auf Ihr Unternehmen
                    </md-tooltip>
                </md-icon>
        </div>
        </div>
        <div class="question-group-table-head-cols rating-header" v-if="visibility.requiredActionVisible">
            <div>
                Handlungs-<br>bedarf
                <md-icon class="information-icon">
                    info_outline
                    <md-tooltip md-direction="top" class="table-head-tooltip">
                        Der Handlungsbedarf zeigt Ihnen auf, welche Wege Sie noch zu gehen haben, um an ihr Soll-Ziel zu gelangen.
                    </md-tooltip>
                </md-icon>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'rgQuestionGroupTableHeadResults',
    data: function() {
        return {};
    },
    props: {
        visibility: {
            type: Object,
            required: true,
        },
    }
}
</script>

<style scoped>
.question-group-table-head-wrapper {
    display: flex;
    flex-direction: row;
}
.question-group-table-head-wrapper .question-group-table-head-cols {
    flex: 1 1 1;

    vertical-align: top;
    text-align: center;

    border-bottom: 2px solid var(--color-dark);
}
.question-spacer {
    width: 40%;
    height: 4em;

    border-bottom: 2px solid var(--color-dark);
}
.question-spacer-divider {
    margin-top: 1em;
    padding: .2em;

    border-top: 2px solid var(--color-dark);
}
.rating-header {
    display: flex;
    flex: 1;

    height: 4em;

    align-items: center;
    justify-content: center;

    border-left: 2px solid var(--color-dark);
}
.information-icon {
    display: inline-block;
    width: 10px;
    height: 10px;

    margin-top: -.5em;

    font-size: 1em !important;    
    line-height: inherit;
}
.postpone {
    width: 12%;
    
    font-size: .7em;
    line-height: 1.4em;

    border-left: 2px solid var(--color-dark);
    border-right: 2px solid var(--color-dark);
}
.table-head-tooltip {
    font-size: var(--font-size-default);
}
.gray {
    background: var(--color-gray);
}
.gray-shade {
    background: var(--color-gray-shade);
}

</style>