<template>
    <div class="detail-results-wrapper">
        <div class="detail-results-header">
            <div class="container">
                <div class="row">
                    <div class="col-12 detail-results-header-container">
                        <div class="space-50" id="detail-results"></div>
                        <div class="detail-results-header-text">
                            <h2>(3) Detailansicht</h2>
                            <p>
                                In der Detailansicht sehen Sie die Ergebnisse zu all Ihren beantworteten Fragen.
                                Es wird Ihnen der IST-Digitalisierungsgrad, SOLL-Digitalisierungsgrad und der Handlungsbedarf
                                zu jeder Fragen in den unterteilten Fragengruppen angezeigt. Zudem haben Sie die Möglichkeit
                                IST-Digitalisierungsgrad, SOLL-Digitalisierungsgrad sowie den Handlungsbedarf aus- und
                                einblenden zu lassen.
                                <br><br>
                                Skala von 0-3 (0 = niedrig, 3 = hoch)
                            </p>
                            <div class="space-20"></div>
                        </div>
                        <div class="detail-results-header-selection">
                            <div class="selection-toggle">
                                <span class="selection-toggle-label">Ist-Digitalisierungsgrad?</span>
                                <md-switch v-model="isState" class="md-primary"></md-switch>
                            </div>
                            <div class="selection-toggle">
                                <span class="selection-toggle-label">Soll-Digitalisierungsgrad?</span>
                                <md-switch v-model="futureState" class="md-primary"></md-switch>
                            </div>
                            <div class="selection-toggle">
                                <span class="selection-toggle-label">Handlungsbedarf?</span>
                                <md-switch v-model="requiredAction" class="md-primary"></md-switch>
                            </div>
                            <div class="space-50"></div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <div class="detail-results-body">
            <rg-survey-results
                v-bind:isStateVisible="isState"
                v-bind:futureStateVisible="futureState"
                v-bind:requiredActionVisible="requiredAction"
            ></rg-survey-results>
        </div>
    </div>
</template>

<script>
import rgSurveyResults from './rgSurveyResults.vue';
export default {
    name: 'rgHouse',
    data: function() {
        return {
            houseViewSelection: 'valueToday',
            isState: true,
            futureState: true,
            requiredAction: true,
        };
    },
    components: { rgSurveyResults },
}
</script>

<style scoped>

.detail-results-header {
    background-color: var(--color-gray);
}
.detail-results-header-container {
    display: flex;
}
.detail-results-header-text {
    max-width: 35em;
}
.detail-results-header-selection {
    display: flex;
    flex-direction: column;

    justify-content: start;

    padding-top: 2em;
}
.selection-toggle {
    display: flex;

    min-width: 15em;

    margin-bottom: -.5em;
    
    align-items: center;
    justify-content: flex-end;
}
.selection-toggle-label {
    margin-right: 1em;
}

</style>